import { Injectable } from '@angular/core';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter, } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GenericInsert, ManagementUser, NoticesDetailInsert, ResetPasswordResponse, ResolutionsInsert, transparentAdministrationResponseType1 } from 'src/app/shared/messageType';
import { LoginService } from './login.service';
@Injectable({
  providedIn: 'root'
})
export class ManagementService extends BaseService {

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly PostRegisterPath = '/api/v1/register';

  /**

  */
  PostRegister$Response(params: ManagementUser): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostRegisterPath, 'post');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
  
   */
  PostRegister(params: ManagementUser): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PostRegister$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }


  /*
   POST All users

 */

  static readonly PostTotalAdminUsersPath = '/api/v1/totaladminuser';

  /**
 
  */
  PostTotalAdminUsers$Response(): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostTotalAdminUsersPath, 'post');
    rb.header('Authorization', 'Bearer ' + token);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }>;
      }),

    );
  }

  /**
  
   */
  PostTotalAdminUsers(): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }> {

    return this.PostTotalAdminUsers$Response().pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> })
    );
  }



  /*
    POST All users

  */

  static readonly PostAllUsersPath = '/api/v1/alluser';

  /**

  */
  PostAllUsers$Response(): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostAllUsersPath, 'post');
    rb.header('Authorization', 'Bearer ' + token);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }>;
      }),

    );
  }

  /**
  
   */
  PostAllUsers(): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }> {

    return this.PostAllUsers$Response().pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ManagementUser> })
    );
  }


  /**
   * RESET PASSWORD
   */
  PostResetPassword$Response(params?: {
    email?: string;
    password?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, LoginService.PostResetPasswordPath, 'post');
    rb.header('Authorization', 'Bearer ' + token);

    if (params) {
      rb.body(params, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }>;
      }),

    );
  }

  /**

   */
  PostResetPassword(params?: {
    email?: string;
    password?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }> {

    return this.PostResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<ResetPasswordResponse> })
    );
  }



  /*
    DELETE User

  */

  static readonly DeletePath = '/api/v1/deleteuser';
  DeleteUser$Response(params?: {
    email?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeletePath, 'delete');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  DeleteUser(params?: {
    email?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.DeleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }


  /*
   DELETE User

 */

  static readonly UpdateUserPath = '/api/v1/updateuser';
  UpdateUser$Response(params?: ManagementUser): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, ManagementService.UpdateUserPath, 'put');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  UpdateUser(params?: ManagementUser): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.UpdateUser$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  /**
   * /api/v1/typebit
   */

  static readonly TypebitPath = '/api/v1/typebit';
  Typebit$Response(email): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, ManagementService.TypebitPath, 'post');
    rb.header('Authorization', 'Bearer ' + token);
    if (email) {
      rb.body({ email: email }, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  Typebit(email?: string): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.Typebit$Response(email).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /**
   * /api/v1/publish_roles
   */

  static readonly PublishRolesPath = '/api/v1/publish_roles';
  PublishRoles$Response(tipo_pubblicazione): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {
    const token = localStorage.getItem('token');
    const rb = new RequestBuilder(this.rootUrl, ManagementService.PublishRolesPath, 'post');
    rb.header('Authorization', 'Bearer ' + token);
    if (tipo_pubblicazione) {
      rb.body({ idPubblicazione: tipo_pubblicazione }, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  PublishRoles(email?: string): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.PublishRoles$Response(email).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /**
   *  /api/v1/generic
   */
  static readonly PostGenericPath = '/api/v1/generic';


  postGenericDetails$Response(params?: {
    CodPadre?: string;
    published?: number;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostGenericPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> }>;
      }),

    );
  }

  /**

   */
  postGenericDetails(params?: {
    CodPadre?: string;
    published?: number;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> }> {

    return this.postGenericDetails$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> })
    );
  }


  /**
   *  /api/v1/generic_document
   */
  static readonly PostGenericDocumentPath = '/api/v1/generic_document';

  postGenericDocument$Response(params?: {
    CodicePadre?: string;
    NomeFile?: string;
    filter?: string;
    order?: string;
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> }>> {
    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostGenericDocumentPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> }>;
      }),

    );
  }

  /**
 
   */
  postGenericDocument(params?: {
    CodicePadre?: string;
    NomeFile?: string;
    filter?: string;
    order?: string;
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.postGenericDocument$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<transparentAdministrationResponseType1> })
    );
  }


  /**
   *  /api/v1/generic
   */
  static readonly PostGenericDocumentInsertPath = '/api/v1/generic_document/insert';
  PostGenericDocumentInsert$Response(params?: GenericInsert): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostGenericDocumentInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  PostGenericDocumentInsert(params?: GenericInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PostGenericDocumentInsert$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  /**
   *  /api/v1/generic/insert
   */
  static readonly PostGenericInsertPath = '/api/v1/generic/insert';
  PostGenericInsert$Response(params?: GenericInsert): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostGenericInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  PostGenericInsert(params?: GenericInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PostGenericInsert$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  /**
 *  /api/v1/generic/update
 */

  static readonly PutGenericUpdatePath = '/api/v1/generic/update';
  PutGenericUpdate$Response(params?: GenericInsert): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {
    const rb = new RequestBuilder(this.rootUrl, ManagementService.PutGenericUpdatePath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  PutGenericUpdate(params?: GenericInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PutGenericUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) =>
        r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }


  /**
  *  /api/v1/generic_document/delete
  */
  static readonly DeleteGenericDocumentPath = '/api/v1/generic_document/delete';
  DeleteGenericDocument$Response(params?: {
    ID: number
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteGenericDocumentPath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  DeleteGenericDocument(params?: {
    ID: number
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.DeleteGenericDocument$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  /**
 *  /api/v1/generic/delete
 */
  static readonly DeleteGenericPath = '/api/v1/generic/delete';
  DeleteGeneric$Response(params?: {
    ID: number
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteGenericPath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  /**
 
   */
  DeleteGeneric(params?: {
    ID: number
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteGeneric$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }



  /**
 *  /api/v1/generic/select_document
 */
  static readonly SelectDocumentGenericPath = '/api/v1/generic/select_document';
  SelectDocumentGeneric$Response(params?: {
    ID: number
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.SelectDocumentGenericPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  /**
 
   */
  SelectDocumentGeneric(params?: {
    ID: number
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.SelectDocumentGeneric$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }


  /**
  *  /api/v1/generic/insert
  */
  static readonly PostResolutionsInsertPath = '/api/v1/resolutions/insert';
  PostResolutionsInsert$Response(params?: ResolutionsInsert): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostResolutionsInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
 
   */
  PostResolutionsInsert(params?: ResolutionsInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PostResolutionsInsert$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  static readonly DeleteResolutionsPath = '/api/v1/resolutions/delete';
  DeleteResolutions$Response(params?: {
    resolution_id: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteResolutionsPath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  /**
 
   */
  DeleteResolutions(params?: {
    resolution_id: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteResolutions$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  /**
   * /api/v1/notices/_insert
   */


  static readonly PostNoticesInsertPath = '/api/v1/notices/_insert';
  PostNoticesInsert$Response(params?:
    NoticesDetailInsert
  ): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostNoticesInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }


  static readonly PostAvvisiInsertPath = '/api/v1/alerts/_insert';
  PostAvvisiInsert$Response(params?:
    NoticesDetailInsert
  ): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostAvvisiInsertPath, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  /**
 
   */
  PostNoticesInsert(params?: NoticesDetailInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {
    return this.PostNoticesInsert$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  PostAvvisiInsert(params?: NoticesDetailInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {
    return this.PostAvvisiInsert$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  static readonly PostNoticesDocumentInsertPath1 = '/api/v1/notices/';
  static readonly PostNoticesDocumentInsertPath2 = '/documents/_insert';
  PostNoticesDocumentInsert$Response(params?:
    NoticesDetailInsert
    , idbando?: number): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostNoticesDocumentInsertPath1 + idbando + ManagementService.PostNoticesDocumentInsertPath2, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }


  static readonly PostAvvisiDocumentInsertPath1 = '/api/v1/alerts/';
  static readonly PostAvvisiDocumentInsertPath2 = '/documents/_insert';
  PostAvvisiDocumentInsert$Response(params?:
    NoticesDetailInsert
    , idavviso?: number): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PostAvvisiDocumentInsertPath1 + idavviso + ManagementService.PostNoticesDocumentInsertPath2, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
   
   */
  PostNoticesDocumentInsert(params?: NoticesDetailInsert, idbando?: number): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PostNoticesDocumentInsert$Response(params, idbando).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  PostAvvisiDocumentInsert(params?: NoticesDetailInsert, idavviso?: number): Observable<{ 'rowCount'?: number, 'message'?: string | null }> {

    return this.PostAvvisiDocumentInsert$Response(params, idavviso).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  static readonly DeleteNoticesPath = '/api/v1/notices/_delete';
  DeleteNotices$Response(params?: {
    notice_id: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteNoticesPath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  static readonly DeleteAvvisiPath = '/api/v1/alerts/_delete';
  DeleteAvvisi$Response(params?: {
    alert_id: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteAvvisiPath, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
   
   */
  DeleteNotices(params?: {
    notice_id: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteNotices$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  DeleteAvvisi(params?: {
    alert_id: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteAvvisi$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  static readonly DeleteNoticesDocumentPath1 = '/api/v1/notices/';
  static readonly DeleteNoticesDocumentPath2 = '/documents/_delete';
  DeleteNoticesDocument$Response(id: any, params?: {
    idBando: string
  },): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteNoticesDocumentPath1 + id + ManagementService.DeleteNoticesDocumentPath2, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  static readonly DeleteAvvisiDocumentPath1 = '/api/v1/alerts/';
  static readonly DeleteAvvisiDocumentPath2 = '/documents/_delete';
  DeleteAvvisiDocument$Response(id: any, params?: {
    idAvviso: string
  },): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteAvvisiDocumentPath1 + id + ManagementService.DeleteAvvisiDocumentPath2, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
   
   */
  DeleteNoticesDocument(id: any, params?: {
    idBando: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteNoticesDocument$Response(id, params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }


  DeleteAvvisiDocument(id: any, params?: {
    idAvviso: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteAvvisiDocument$Response(id, params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }



  static readonly DeleteNoticesDocumentPath3 = '/documents/single_delete';
  DeleteNoticesSingleDocument$Response(id: any, params?: {
    ID: string
  },): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteNoticesDocumentPath1 + id + ManagementService.DeleteNoticesDocumentPath3, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }


  static readonly DeleteAvvisiDocumentPath3 = '/documents/single_delete';
  DeleteAvvisiSingleDocument$Response(id: any, params?: {
    ID: string
  },): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.DeleteAvvisiDocumentPath1 + id + ManagementService.DeleteAvvisiDocumentPath3, 'delete');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }

  /**
   
   */
  DeleteNoticesSingleDocument(id: any, params?: {
    ID: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteNoticesSingleDocument$Response(id, params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  DeleteAvvisiSingleDocument(id: any, params?: {
    ID: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.DeleteAvvisiSingleDocument$Response(id, params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }

  static readonly pathGetAllDocument = '/api/v1/generic/_getall';

  GetAllDocument$Response(): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>> {
    const rb = new RequestBuilder(this.rootUrl, ManagementService.pathGetAllDocument, 'post');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>;
      }),

    );
  }
  /**
   
   */
  GetAllDocument(): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {
    return this.GetAllDocument$Response().pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as { 'rowCount'?: number, 'message'?: string | null })
    );
  }


  /**
   * /api/v1/notices//api/v1/notices/_update
   */


  static readonly PutNoticesUpdatePath = '/api/v1/notices/_update';
  PutNoticesUpdate$Response(params?:
    NoticesDetailInsert
  ): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PutNoticesUpdatePath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  static readonly PutAvvisiUpdatePath = '/api/v1/alerts/_update';
  PutAvvisiUpdate$Response(params?:
    NoticesDetailInsert
  ): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PutAvvisiUpdatePath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  /**
 
   */
  PutNoticesUpdate(params?: NoticesDetailInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {
    return this.PutNoticesUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

  PutAvvisiUpdate(params?: NoticesDetailInsert): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {
    return this.PutAvvisiUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null }>) => r.body as { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }






  static readonly PutUpdatePublishPath = '/api/v1/generic_document/update_publish';

  /*
* UPDATE PUBLISH
*/
  UpdatePublisGenerics$Response(params?: {
    ID: string,
    published: string
  }): Observable<StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>> {

    const rb = new RequestBuilder(this.rootUrl, ManagementService.PutUpdatePublishPath, 'put');
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>;
      }),

    );
  }

  UpdatePublisGenerics(params?: {
    ID: string,
    published: string
  }): Observable<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }> {

    return this.UpdatePublisGenerics$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> }>) => r.body as
        { 'rowCount'?: number, 'message'?: string | null, 'rows'?: Array<any> })
    );
  }

}



