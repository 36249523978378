<!--start card-->
<div class="shared_information_column" style="overflow: auto">
  <app-cell-info
    *ngIf="settingUserIsAvailable()"
    [minHeight]="'50px'"
    [title]="'MANAGEMENT_TASK.USER_MANAGEMENT'"
    [showDetail]="false"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    (actionFunction)="goToUserManagement()"
  >
  </app-cell-info>
  <app-cell-info
    [minHeight]="'50px'"
    [title]="'MANAGEMENT_TASK.DOCUMENTS_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="documentsIsAvailable()"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    (actionFunction)="goToDocument()"
  >
  </app-cell-info>
  <app-cell-info
    [minHeight]="'50px'"
    [title]="'MANAGEMENT_TASK.NEWS_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="newsIsAvailable()"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    (actionFunction)="goToNews()"
  >
  </app-cell-info>

  <!-- ! ==== Not used anymore but preserve the BIT ==== -->
  <!--<app-cell-info  [title]="'Gestione sinistri ed eventi avversi'" [showDetail]="false"
    *ngIf="pagesIsAvailable()" [detailText]="'BUTTON.GO_TO_PAGE'" (actionFunction)="goToClaims()">
  </app-cell-info> -->
  <!-- ! =============================================== -->

  <app-cell-info
    [minHeight]="'50px'"
    [title]="'MANAGEMENT_TASK.TRANSPARENT_ADMINSTRATION_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="transparentAdminIsAvailable()"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    (actionFunction)="goToTransparentAdministration()"
  >
  </app-cell-info>
  <app-cell-info
    [minHeight]="'50px'"
    [title]="'MANAGEMENT_TASK.ETHICS_COMMITEE_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="ethicsIsAvailable()"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    (actionFunction)="goToEthicsCommitee()"
  >
  </app-cell-info>
  <app-cell-info
    [minHeight]="'50px'"
    [title]="'MANAGEMENT_TASK.RISK_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="riskIsAvailable()"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    (actionFunction)="goToRiskManager()"
  >
  </app-cell-info>
  <app-cell-info
    [minHeight]="'50px'"
    [title]="'MANAGEMENT_TASK.DEPARTMENTS_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="departmentsIsAvailable()"
    (actionFunction)="goToDepartmentManager()"
  >
  </app-cell-info>
  <app-cell-info
    [minHeight]="'50px'"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    [title]="'MANAGEMENT_TASK.DIRECTION_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="companyIsAvailable()"
    (actionFunction)="goToCompanyManager()"
  >
  </app-cell-info>

  <app-cell-info
    [minHeight]="'50px'"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    [title]="'MANAGEMENT_TASK.PROC_AZIENDALI_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="procAziendaliIsAvailable()"
    (actionFunction)="goToProcAziendali()"
  >
  </app-cell-info>

  <app-cell-info
    [minHeight]="'50px'"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    [title]="'MANAGEMENT_TASK.RLS_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="rlsIsAvailable()"
    (actionFunction)="goToRls()"
  >
  </app-cell-info>

  <app-cell-info
    [minHeight]="'50px'"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    [title]="'MANAGEMENT_TASK.ENDO_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="endoIsAvailable()"
    (actionFunction)="goToEndo()"
  >
  </app-cell-info>

  <app-cell-info
    [minHeight]="'50px'"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    [title]="'MANAGEMENT_TASK.MANUALE_QUALITA_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="manualeQualitaIsAvailable()"
    (actionFunction)="goToManualeQualita()"
  >
  </app-cell-info>

  <app-cell-info
    [minHeight]="'50px'"
    [detailText]="'BUTTON.GO_TO_PAGE'"
    [title]="'MANAGEMENT_TASK.HOME_MANAGEMENT'"
    [showDetail]="false"
    *ngIf="homeIsAvailable()"
    (actionFunction)="goToHomeManager()"
  >
  </app-cell-info>
</div>
<div
  *ngIf="globalAvailability()"
  style="justify-content: center; display: flex"
>
  <div class="callout danger">
    <div class="callout-title">
      <svg class="icon">
        <use xlink:href="../../../assets/sprite.svg#it-close-circle"></use>
      </svg>
      {{ "USER_MANAGEMENT.DISABLED_USER" | translate }}
    </div>
    <p class="text-alert">
      {{ "USER_MANAGEMENT.DISABLE_USER_MESSAGE" | translate }}
    </p>
    <p class="text-alert">
      {{ "USER_MANAGEMENT.CONTACT_ADMIN_MESSAGE" | translate }}
    </p>
  </div>
</div>
