import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RoleUserEnum } from 'src/app/shared/enums/roleUsersEnum';
import { LoaderService } from 'src/app/api/services/loader.service';
import { ManagementService } from 'src/app/api/services/management.service';
import { GenericInfoModalService } from '../generic-info-modal/generic-info-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentsService } from 'src/app/pages/back-office/documents/documents.service';
import { GLOBAL_BIT_NUM } from 'src/app/shared/models/constants';

@Component({
  selector: 'app-management-task',
  templateUrl: './management-task.component.html',
  styleUrls: ['./management-task.component.scss'],
})
export class ManagementTaskComponent implements OnInit {
  loaderPage = false;
  role: RoleUserEnum;
  BIT_DATA = GLOBAL_BIT_NUM;

  constructor(
    private router: Router,
    private location: Location,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private managementService: ManagementService,
    private infoModal: GenericInfoModalService,
    private documentService: DocumentsService
  ) { }

  ngOnInit(): void {
    this.location.replaceState('/management/settings');
    this.getRoleUser();
  }

  newsIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 7] === '1'
      : false;
  }

  ethicsIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 10] === '1'
      : false;
  }

  riskIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 13] === '1'
      : false;
  }
  departmentsIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
  }
  companyIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 15] === '1'
      : false;
  }

  procAziendaliIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 15] === '1'
      : false;
  }

  rlsIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 15] === '1'
      : false;
  }

  endoIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 15] === '1'
      : false;
  }

  manualeQualitaIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 15] === '1'
      : false;
  }

  homeIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
  }

  documentsIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione
        ?.substring(this.BIT_DATA - 13, this.BIT_DATA - 12)
        .includes('1') || // *Atti di noticica & Concorsi
      tipo_pubblicazione
        ?.substring(this.BIT_DATA - 12, this.BIT_DATA - 10)
        .includes('1') || // * Campania Pancreas & CIO
      tipo_pubblicazione
        ?.substring(this.BIT_DATA - 1, this.BIT_DATA)
        .includes('1') || // * Bandi
      tipo_pubblicazione
        ?.substring(this.BIT_DATA - 15, this.BIT_DATA - 14)
        .includes('1') // * Privacy
      : false;
  }

  pagesIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 6] === '1' ||
      tipo_pubblicazione[this.BIT_DATA - 3] === '1'
      : false;
  }

  transparentAdminIsAvailable() {
    if (this.role && this.role === RoleUserEnum.ADMIN) {
      return true;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    return tipo_pubblicazione
      ? tipo_pubblicazione[this.BIT_DATA - 2] === '1'
      : false;
  }

  settingUserIsAvailable() {
    return this.role && this.role === RoleUserEnum.ADMIN;
  }

  globalAvailability() {
    return (
      this.role &&
      !this.newsIsAvailable() &&
      !this.documentsIsAvailable() &&
      !this.settingUserIsAvailable() &&
      !this.pagesIsAvailable() &&
      !this.ethicsIsAvailable() &&
      !this.transparentAdminIsAvailable() &&
      !this.riskIsAvailable() &&
      !this.companyIsAvailable()
    );
  }

  private getRoleUser() {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    const user = JSON.parse(localStorage.getItem('user'));
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    this.managementService.Typebit(user?.email).subscribe(
      (res: any) => {
        if (res && res.rowCount > 0) {
          this.role = this.documentService.settingRole(res, tipo_pubblicazione);
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: {},
          });
        }
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        console.error(err);
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  checkTokenExpired(err) {
    if (err.status === 401) {
      this.infoModal.setModal({
        show: true,
        type: 'error',
        title: this.translate.instant('ALERT_MESSAGE.SESSION_EXPIRED'),
        bodyText: this.translate.instant(
          'ALERT_MESSAGE.SESSION_EXPIRED_MESSAGE'
        ),
        actionLabel: this.translate.instant('BUTTON.CONFIRM'),
        actionFunction: function () {
          this.resetToken();
        }.bind(this),
      });
    } else {
      this.infoModal.setModal({
        show: true,
        type: 'error',
        title: this.translate.instant('DOCUMENTS.ERROR'),
        bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
        actionLabel: this.translate.instant('BUTTON.OK'),
        actionFunction: function () {
          this.resetToken();
        }.bind(this),
      });
    }
  }

  resetToken() {
    this.location.replaceState('/management/login');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
  }

  goToUserManagement() {
    this.router.navigateByUrl('/management/user-management');
  }
  goToDocument() {
    this.router.navigateByUrl('/management/documents');
  }
  goToNews() {
    this.router.navigateByUrl('/management/news-documental');
  }
  goToClaims() {
    this.router.navigateByUrl('/management/pages-documental');
  }

  goToEthicsCommitee() {
    this.router.navigateByUrl('/management/ethics-documental');
  }
  goToRiskManager() {
    this.router.navigateByUrl('management/risk-documental');
  }
  goToDepartmentManager() {
    this.router.navigateByUrl('/management/department-documental');
  }

  goToCompanyManager() {
    this.router.navigateByUrl('/management/company-documental');
  }

  goToProcAziendali(){
    this.router.navigateByUrl('/management/procedure-aziendali-documental');
  }

  goToRls(){
    this.router.navigateByUrl('/management/rls-documental');
  }

  goToEndo(){
    this.router.navigateByUrl('/management/endo-documental');
  }

  goToManualeQualita(){
    this.router.navigateByUrl('/management/manuale-qualita-documental');
  }

  goToHomeManager() {
    this.router.navigateByUrl('/management/home-documental');
  }

  goToTransparentAdministration() {
    this.router.navigateByUrl(
      '/management/transparent-administration-documental'
    );
  }
}
