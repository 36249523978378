<!-- HEAD -->
<app-main-head
  *ngIf="notice_Detail_Response"
  [breadcrumbItems]="breadcrumbItems"
  [isSearchRequired]="false"
  [mainTitle]="notice_Detail_Response.titolo"
  [isRightPanelVisible]="false"
>
</app-main-head>
<!-- END HEAD -->
<app-avvisi-detail-body
  *ngIf="notice_Detail_Response"
  [notice_Detail_Response]="notice_Detail_Response"
>
</app-avvisi-detail-body>
