import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IS_MOBILE } from 'src/app/shared/models/constants';
import * as moment from 'moment';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';
@Component({
  selector: 'app-table-info',
  templateUrl: './table-info.component.html',
  styleUrls: ['./table-info.component.scss'],
})
export class TableInfoComponent implements OnInit {
  @Input() postTypeData;
  @Input() showDate = false;
  @Input() data;
  @Output() downloadHandler = new EventEmitter();
  mobile = IS_MOBILE;

  constructor() {}

  ngOnInit(): void {}

  retrieveDate(date: any) {
    if (date) {
      const validFormats = [
        'DD/MM/YYYY',
        'DD-MM-YYYY',
        'YYYY/MM/DD',
        'YYYY-MM-DD',
        'x',
      ];
      let momentDate = moment(date, validFormats, true);
      if (!momentDate.isValid()) {
        momentDate = moment(date);
      }
      return momentDate.format('LL') || '-';
    }
    return '-';
  }

  download(element) {
    this.downloadHandler.emit(element);
  }

  getTitleName(elem) {
    switch (this.postTypeData) {
      case DocumentPostType.WHISTLEBLOWING:
      case DocumentPostType.CIO_COM:
      case DocumentPostType.ATTI_NOT:
      case DocumentPostType.CAM_PANCREAS:
      case DocumentPostType.COM_ETIC:
      case DocumentPostType.PROCAZIENDALI:
      case DocumentPostType.RISK_MANAGER:
      case DocumentPostType.PRIVACY:
      case DocumentPostType.RLS:
      case DocumentPostType.ENDO:
      case DocumentPostType.MANUALE_QUALITA:

        return elem.Titolo;
      case DocumentPostType.GENERICS:
        return elem.NomeDocumento;
      default:
        return '';
    }
  }
}
