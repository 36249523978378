import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/api/services/loader.service';
import {
  noticesDetailResponse,
  noticesDocumentsResponse,
  noticesPlaceResponse,
} from 'src/app/shared/messageType';
import { DownloadService } from 'src/app/api/services/download.service';
import { AvvisiService } from 'src/app/api/services/avvisi.service';

declare var require: any;
@Component({
  selector: 'app-avvisi-detail-body',
  templateUrl: './avvisi_detail_body.component.html',
  styleUrls: [
    './avvisi_detail_body.component.scss',
    '../../shared/sharedStyle.scss',
  ],
})
export class AvvisiDetailBodyComponent implements OnInit {
  isLoadingDocument: boolean[] = [];
  sprite = require('src/assets/sprite.svg').default;
  constructor(
    private ns: AvvisiService,
    private loaderService: LoaderService,
    private downloadService: DownloadService
  ) {}
  loaderPage = false;
  @Input() notice_Detail_Response: noticesDetailResponse;
  notice_Document_Response: noticesDocumentsResponse[];

  notice_Place_Response: noticesPlaceResponse;

  ngOnInit(): void {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.ns
      .postSearchAvvisiDocuments({
        alert_id: this.notice_Detail_Response.ID.toString(),
      })
      .subscribe((res) => {
        this.notice_Document_Response = res.rows;
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        for (
          let index = 0;
          index < this.notice_Document_Response.length;
          index++
        ) {
          this.isLoadingDocument[index] = false;
        }
      });
    if (this.notice_Detail_Response.Luogo) {
      this.ns
        .PostAvvisiPlace(
          this.notice_Detail_Response.Luogo,
          this.notice_Detail_Response.ID
        )
        .subscribe((res) => {
          this.notice_Place_Response = res.rows[0];
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        });
    }
  }
  redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
  getCircleClass(status) {
    switch (status) {
      case 0:
        return 'task_done';
      case 1:
        return 'task_in_progress';
      case 2:
        return 'future_task';
    }
  }

  downloadFile(element: noticesDocumentsResponse, index) {
    this.isLoadingDocument[index] = true;
    const post_type = 'alerts';
    const post_id = element.ID ? element.ID.toString() : '';
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      post_type +
      DownloadService.PostDownload2Path +
      post_type +
      '/' +
      post_id;
    this.downloadService.downloadBlob(element.filename, url);
    this.isLoadingDocument[index] = false;
  }
}
