import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';

import { Observable } from 'rxjs';
import { EndoType } from 'src/app/shared/messageType';

@Injectable({
  providedIn: 'root',
})
export class EndoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  static readonly PostSearchPath = '/api/v1/endo_pfd/_search';
  static readonly PostInsertPath = '/api/v1/endo_pfd/_insert';
  static readonly PutUpdatePath = '/api/v1/endo_pfd/_update';
  static readonly PostDeletePath = '/api/v1/endo_pfd/_delete';
  static readonly PutUpdatePublishPath = '/api/v1/endo_pfd/_update_publish';

  /*
   * SEARCH
   */

  postSearchEndo(
    body?: {
      type?: number;
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<EndoType>;
  }> {
    let fullUrl = this.rootUrl + EndoService.PostSearchPath;

    return this.http.post<any>(fullUrl, { ...body, ...params });
  }

  /*
   * INSERT
   */

  postInsertEndo(params?: {
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<EndoType>;
  }> {
    const headers = this.getHeader();
    let fullUrl = this.rootUrl + EndoService.PostInsertPath;
    return this.http.post<any>(fullUrl, { ...params }, { headers });
  }

  /*
   * UPDATE
   */

  putUpdateEndo(params?: {
    endo_pfd_id: string;
    Titolo?: string;
    File?: string;
    published?: string;
    NomeFile?: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<EndoType>;
  }> {
    const headers = this.getHeader();
    let fullUrl = this.rootUrl + EndoService.PutUpdatePath;
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }

  /*
   * DELETE
   */

  DeleteEndo(params?: { endo_pfd_id: string }) {
    let fullUrl = this.rootUrl + EndoService.PostDeletePath;
    const headers = this.getHeader();
    return this.http.request<any>('delete', fullUrl, {
      body: { ...params },
      headers: headers,
    });
  }

  /*
   * UPDATE PUBLISH
   */

  UpdatePublishEndo(params?: { endo_pfd_id: string; published: string }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    let fullUrl = this.rootUrl + EndoService.PutUpdatePublishPath;
    const headers = this.getHeader();
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }
  getHeader() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return headers;
  }
}
