import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from 'src/app/api/services/download.service';
import { EndoService } from 'src/app/api/services/endo.service';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { DocumentPostType } from 'src/app/shared/enums/documentPostTypeEnum';

import { EndoType } from 'src/app/shared/messageType';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-endo-pfd',
  templateUrl: './endo-pfd.component.html',
  styleUrls: ['./endo-pfd.component.scss'],
})
export class EndoComponent implements OnInit {
  breadcrumbItems: BreadcrumbItem[] = [
    new BreadcrumbItem('NAVBAR_TAB.HOME', '/home'),
    new BreadcrumbItem('NAVBAR_TAB.COMPANY', '/azienda'),
    new BreadcrumbItem('NAVBAR_TAB.ENDO_PFD', ''),
  ];

  constructor(
    private endos: EndoService,
    private loaderService: LoaderService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private downloadService: DownloadService
  ) {}

  loaderPage = false;
  detailInfo: EndoType[];
  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    var body: any = {};
    body.published = '1';
    body.filter = 'Titolo';
    body.order = 'ASC';
    this.endos.postSearchEndo(body).subscribe(
      (result) => {
        this.detailInfo = result.rowCount > 0 ? result.rows : [];
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.infoModal.setModal({
          show: true,
          type: 'error',
          title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
          bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
          actionLabel: this.translate.instant('BUTTON.OK'),
          actionFunction: () => {},
        });
      }
    );
  }

  public downloadFile(doc) {
    const postType = DocumentPostType.ENDO;
    const postID = doc.ID;
    const url =
      this.downloadService.rootUrl +
      DownloadService.PostDownload1Path +
      postType +
      DownloadService.PostDownload2Path +
      postType +
      '/' +
      postID;
    this.downloadService.downloadBlob(doc.Filename, url);
  }

  public redirectTo(elementId) {
    document.getElementById(elementId).scrollIntoView();
  }
}
