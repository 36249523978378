<div class="it-header-wrapper it-shadow">
  <div class="app-accessibility-button-wrapper">
    <app-accessibility-button></app-accessibility-button>
  </div>
  <div class="it-nav-wrapper">
    <!-- top -->
    <app-header-top></app-header-top>
    <!--  Navbar -->
    <div class="it-header-navbar-wrapper theme-light-desk">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <!--start nav-->
            <nav class="navbar navbar-expand-lg has-megamenu">
              <button
                class="custom-navbar-toggler"
                type="button"
                aria-controls="nav03"
                aria-expanded="false"
                style="padding: 0 1em"
                aria-label="Toggle navigation"
                (click)="isOpenMenu = !isOpenMenu"
              >
                <svg-icon-sprite src="../../../assets/sprite.svg#it-burger">
                </svg-icon-sprite>
              </button>
              <div
                [ngStyle]="{ display: isOpenMenu ? 'block' : 'none' }"
                style="z-index: 999"
                [ngClass]="
                  isOpenMenu
                    ? 'navbar-collapsable expanded'
                    : 'navbar-collapsable'
                "
              >
                <div
                  class="overlay"
                  [ngStyle]="{ display: isOpenMenu ? 'block' : 'none' }"
                ></div>
                <div class="close-div sr-only">
                  <button class="btn close-menu" type="button">
                    <span class="it-close"></span
                    >{{ "HEADER.CLOSE" | translate }}
                  </button>
                </div>
                <div class="menu-wrapper menu-wrapper-overflow">
                  <ul
                    class="navbar-nav nav-ul"
                    style="width: 100%; justify-content: start"
                  >
                    <li
                      *ngFor="let navItem of navItems"
                      class="nav-item"
                      [class.dropdown]="
                        (navItem.subMenu && navItem.subMenu.length) ||
                        (navItem.links && navItem.links.length)
                      "
                      (click)="onChangeTab($event, tab)"
                    >
                      <a
                        style="text-decoration: none"
                        class="nav-link"
                        [class.dropdown-toggle]="
                          (navItem.subMenu && navItem.subMenu.length) ||
                          (navItem.links && navItem.links.length)
                        "
                        data-toggle="dropdown"
                        aria-expanded="false"
                        [class.active]="
                          navItem.path
                            ? selectedTab == navItem.path.slice(1)
                            : false
                        "
                        [routerLink]="
                          !navItem.links ||
                          (navItem.links &&
                            !navItem.links.length &&
                            navItem.nav_id !== 4 &&
                            navItem.nav_id !== 66)
                            ? navItem.path
                            : null
                        "
                        (click)="
                          hasSubMenu(navItem) ? onChangeTab($event, tab) : null
                        "
                        ><span
                          #tab
                          [id]="navItem.path ? navItem.path.slice(1) : ''"
                        >
                          {{ navItem.title | translate }}</span
                        ><span class="sr-only">current</span>
                        <svg-icon-sprite
                          class="expand-icon"
                          *ngIf="
                            (navItem.subMenu && navItem.subMenu.length) ||
                            (navItem.links && navItem.links.length)
                          "
                          src="../../../assets/sprite.svg#it-expand"
                        ></svg-icon-sprite>
                      </a>
                      <!---->
                      <div
                        [class.dropdown-menu2]="navItem.nav_id === 66"
                        class="dropdown-menu white margin-top-on-mobile-submenu"
                        *ngIf="navItem.subMenu && navItem.subMenu.length"
                      >
                        <div class="row subMenuOnMobile">
                          <div *ngFor="let subMenu of navItem.subMenu">
                            <div class="link-list-wrapper">
                              <ul class="link-list">
                                <li>
                                  <h3 class="no_toc">
                                    {{ subMenu.title | translate }}
                                  </h3>
                                </li>
                                <ng-container>
                                  <li
                                    *ngFor="
                                      let subLink of subMenu.subLinks
                                        | slice : 0 : 7
                                    "
                                  >
                                    <a
                                      class="list-item font-size-inherit-mobile"
                                      [routerLink]="
                                        subLink.nav_item_link || subLink.path
                                      "
                                      ><span class="dropdown_link">{{
                                        subLink.title | translate
                                      }}</span></a
                                    >
                                  </li>
                                </ng-container>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="dropdown-menu white subMenuOnMobile margin-top-on-mobile-submenu"
                        style="width: auto"
                        *ngIf="navItem.links && navItem.links.length"
                      >
                        <div class="link-list-wrapper padding-vertical0-mobile">
                          <ul class="link-list">
                            <li *ngFor="let link of navItem.links">
                              <a
                                class="nav-link padding-vertical0-mobile font-size-inherit-mobile"
                                (click)="redirectTo(link.nav_item_link, link.nav_id)"
                                ><span>{{ link.title | translate }} </span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <div
                      class="dropdown link-list-wrapper nav-item"
                      tabindex="0"
                    ></div>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
