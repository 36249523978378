import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { PermalinksService } from './api/services/permalinks.service';
import { SharedData } from './shared/sharedData';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  checkIfSectionShouldBeVisible(): boolean {
    {
      if (
        this.router.url.indexOf('secrePath') < 0 &&
        this.router.url.indexOf('documents') < 0 &&
        this.router.url.indexOf('news-documental') < 0 &&
        this.router.url.indexOf('pages-documental') < 0 &&
        this.router.url.indexOf('ritiro-cartella-clinica') < 0 &&
        this.router.url.indexOf('login') < 0 &&
        this.router.url.indexOf('permalink') < 0 &&
        this.router.url.indexOf('user-management') < 0 &&
        this.router.url.indexOf('ethics-documental') < 0 &&
        this.router.url.indexOf('transparent-administration-documental') < 0 &&
        this.router.url.indexOf('risk-documental') < 0 &&
        this.router.url.indexOf('department-documental') < 0 &&
        this.router.url.indexOf('company-documental') < 0 &&
        this.router.url.indexOf('home-documental') < 0 &&
        this.router.url.indexOf('procedure-aziendali-documental') < 0 &&
        this.router.url.indexOf('rls-documental') < 0 &&
        this.router.url.indexOf('endo-documental') < 0 &&
        this.router.url.indexOf('manuale-qualita-documental') < 0 
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  title =
    'Azienda Ospedaliera Universitaria | Università degli Studi della Campania Luigi Vanvitelli';
  navItems: any[] = [];
  navRightPages: any[] = [];
  riskManagementNavItems: any[] = [];
  companyNavItems: any[] = [];
  administrationNavItems: any[] = [];
  isHeaderVisible: boolean = true;
  constructor(
    public router: Router,
    private translateService: TranslateService,
    private sharedData: SharedData
  ) {
    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // window.scrollTo(0, 0);
      });
    moment.locale('it');
  }

  ngOnInit() {
    setActionAccesibility();
    this.navItems = this.sharedData.navItems;
    // this.navRightPages = this.sharedData.navRightPages;

    this.administrationNavItems =
      this.sharedData.navItems[1].subMenu[2].subLinks;
    this.companyNavItems = this.sharedData.navItems[1].subMenu[0].subLinks[0];
    // this.riskManagementNavItems = this.sharedData.navLeftPages[1]
  }
}

function setActionAccesibility() {
  document.body.classList.add('f-15');
}
