import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatesService } from 'src/app/api/services/dates.service';
import { RoleUserEnum } from 'src/app/shared/enums/roleUsersEnum';
import { TypeOperationEnum } from 'src/app/shared/enums/typeOperationEnum';

@Component({
  selector: 'app-table-documents',
  templateUrl: './table-documents.component.html',
  styleUrls: ['./table-documents.component.scss'],
})
export class TableDocumentsComponent implements OnInit {
  @Input() role;
  @Input() typologyName: TypeOperationEnum;
  @Input() documentTypeName;
  @Input() dataSource: any[];
  @Input() filterOrder: 'ASC';
  @Input() filterCategory: any;
  @Input() filterSubCategory: any;
  @Input() otherCategoryEnable = false;
  @Input() otherSubCategoryEnable = false;

  // Output
  @Output() deleteHandler = new EventEmitter();
  @Output() actionHandler = new EventEmitter();
  @Output() readHandler = new EventEmitter();
  @Output() editHandler = new EventEmitter();

  @Output() changeStatusHandler = new EventEmitter();

  // * Department Handlers
  @Output() uOSHandler = new EventEmitter();
  @Output() uOCHandler = new EventEmitter();
  @Output() uOSDHandler = new EventEmitter();
  @Output() programsHandler = new EventEmitter();
  // * Company Handler
  @Output() companyStructuresHandler = new EventEmitter();
  // * User management Handlers
  @Output() disableHandler = new EventEmitter();
  @Output() resetHandler = new EventEmitter();

  readDocument(document, element, readOnly, showBodyModal) {
    const value = { document, element, readOnly, showBodyModal };
    this.readHandler.emit(value);
  }

  editDocument(document, element, readOnly, showBodyModal) {
    const value = { document, element, readOnly, showBodyModal };
    this.editHandler.emit(value);
  }

  changeStatus(document) {
    const value = { document };
    this.changeStatusHandler.emit(value);
  }

  constructor(
    private translate: TranslateService,
    public dates: DatesService
  ) {}

  ngOnInit(): void {}

  didPressUOC(element) {
    this.uOCHandler.emit(element);
  }
  didPressUOS(element) {
    this.uOSHandler.emit(element);
  }
  didPressUOSD(element) {
    this.uOSDHandler.emit(element);
  }
  didPressCompanyStructures(element) {
    this.companyStructuresHandler.emit(element);
  }

  didPressPrograms(element) {
    this.programsHandler.emit(element);
  }
  didPressDelete(element: any, nomePubblicazione) {
    const value = { element, nomePubblicazione };
    this.deleteHandler.emit(value);
  }

  didPressReset(element) {
    this.resetHandler.emit(element);
  }
  disableUser(element) {
    this.disableHandler.emit(element);
  }

  searchBtnPressed(order) {
    this.actionHandler.emit(order);
  }

  checkRolePermission() {
    return this.role !== RoleUserEnum.AUTHOR;
  }

  getNameTitle(name) {
    switch (name) {
      case TypeOperationEnum.CAMPANIA_PANCREAS:
        return this.translate.instant('NAVBAR_TAB.PANCREAS');
      case TypeOperationEnum.CIO:
        return this.translate.instant('NAVBAR_TAB.CIO');
        case TypeOperationEnum.PROCAZIENDALI:
          return this.translate.instant('NAVBAR_TAB.PROCEDURE_AZIENDALI');
          case TypeOperationEnum.RLS:
            return this.translate.instant('NAVBAR_TAB.RLS');
            case TypeOperationEnum.ENDO:
              return this.translate.instant('NAVBAR_TAB.ENDO_PFD');
      case TypeOperationEnum.COMITATO_ETICO:
        return this.translate.instant('NAVBAR_TAB.ETHICS_COMMITEE');
      case TypeOperationEnum.BANDO:
        return this.translate.instant('HOME_SUBVIEWS.TENDERS');
      case TypeOperationEnum.CONCORSI:
        return this.translate.instant('HOME_SUBVIEWS.NOTICES');
      case TypeOperationEnum.AVVISI:
          return this.translate.instant('HOME_SUBVIEWS.AVVISI');
      case TypeOperationEnum.PRIVACY:
        return this.translate.instant('NAVBAR_TAB.PRIVACY');
        case TypeOperationEnum.MANUALE_QUALITA:
        return this.translate.instant('NAVBAR_TAB.MANUALE_QUALITA');
      case TypeOperationEnum.RISK:
        return this.translate.instant('RISK_MANAGER.TITLE');
      default:
        return name;
    }
  }

  initHeaderTable() {
    switch (this.typologyName) {
      case TypeOperationEnum.HOME_CAROUSEL:
        return [
          {
            value: this.translate.instant('USER_MANAGEMENT.USER_NAME'),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.PATH'),
            width: '',
          },
          {
            value: this.translate.instant('USER_MANAGEMENT.STATUS'),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      case TypeOperationEnum.USER_MANAGEMENT:
        return [
          {
            value: this.translate.instant('USER_MANAGEMENT.USER_EMAIL'),
            width: '',
          },
          {
            value: this.translate.instant('USER_MANAGEMENT.USER_NAME'),
            width: '',
          },
          {
            value: this.translate.instant('USER_MANAGEMENT.ROLE'),
            width: '',
          },
          {
            value: this.translate.instant('USER_MANAGEMENT.STATUS'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      case TypeOperationEnum.DIPARTIMENTI:
        return [
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TITLE'),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_PLACE'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_STATUS'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      case TypeOperationEnum.DIPARTIMENTI_UNITA_OPERATIVE:
        return [
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TITLE'),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.NAME_DOCTOR'),
            width: '20%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      case TypeOperationEnum.PRIVACY:
        return [
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TITLE'),
            width: '50%',
            hasSort: true,
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_CATEGORY'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TYPE'),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_STATUS'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      case TypeOperationEnum.COMITATO_ETICO:
        return [
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TITLE'),
            width: '',
            hasSort: true,
          },
          {
            value: this.translate.instant(
              'DOCUMENTS_TABLE.TABLE_PUBLICATION_DATE'
            ),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TYPE'),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_STATUS'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      case TypeOperationEnum.DIREZIONE:
      case TypeOperationEnum.STRUTTURE:
        return [
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TITLE'),
            width: '',
            hasSort: true,
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_STATUS'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      case TypeOperationEnum.NEWS:
        return [
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TITLE'),
            width: '',
            hasSort: true,
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_PUBLICATION_DATE'),
            width: '15%',
            hasSort: true,
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_EXPIRING_DATE'),
            width: '15%',
            hasSort: true,
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_STATUS'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
      default:
        return [
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TITLE'),
            width: '',
            hasSort: true,
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_TYPE'),
            width: '',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_STATUS'),
            width: '15%',
          },
          {
            value: this.translate.instant('DOCUMENTS_TABLE.TABLE_ACTION'),
            width: '5%',
          },
        ];
    }
  }

  generateRows(item) {
    switch (this.typologyName) {
      case TypeOperationEnum.HOME_CAROUSEL:
        return [
          {
            value: item.Titolo,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, false);
            },
          },
          {
            value: item.Link,
            handlerFunction: () => {
              window.open(item.Link);
              this.readDocument(this.typologyName, item, true, false);
            },
            isAlink: true,
          },
          {
            value:
              item.published === '1'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      case TypeOperationEnum.USER_MANAGEMENT:
        return [
          {
            value: item.email,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, false);
            },
          },
          {
            value: item.nome + ' ' + item.cognome,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, false);
            },
          },
          {
            value: item.role,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, false);
            },
          },
          {
            value: this.getActiveStatus(item.tipo_pubblicazione),
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, false);
            },
          },
        ];
      case TypeOperationEnum.COMITATO_ETICO:
        return [
          {
            value: item.Titolo,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, false);
            },
          },
          {
            value: this.dates.transformDate(item.DataIns, 'dd/MM/yyyy'),
          },
          {
            value: this.getNameTitle(this.documentTypeName),
          },

          {
            value:
              item.published === '1'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      case TypeOperationEnum.RISK:
      case TypeOperationEnum.CIO:
      case TypeOperationEnum.PROCAZIENDALI:
      case TypeOperationEnum.RLS:
      case TypeOperationEnum.ENDO:
      case TypeOperationEnum.MANUALE_QUALITA:
      case TypeOperationEnum.ATTI_DI_NOTIFICA:
      case TypeOperationEnum.CAMPANIA_PANCREAS:
      case TypeOperationEnum.BANDO:
      case TypeOperationEnum.CONCORSI:
      case TypeOperationEnum.AVVISI:
        return [
          {
            value: item.Titolo ? item.Titolo : item.titolo,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, true);
            },
          },

          {
            value: this.getNameTitle(this.documentTypeName),
          },

          {
            value:
              item.published === '1'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      case TypeOperationEnum.PRIVACY:
        return [
          {
            value: item.Titolo,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, true);
            },
          },
          {
            value: item.Categoria,
          },

          {
            value: this.getNameTitle(this.documentTypeName),
          },

          {
            value:
              item.published === '1'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      case TypeOperationEnum.DIPARTIMENTI:
        return [
          {
            value: item.Titolo,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, true);
            },
          },
          {
            value: item.Luogo_Dipartimento,
          },

          {
            value:
              item.published === '1'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      case TypeOperationEnum.UOC:
        return [
          {
            value: item.Nome_Struttura,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, true);
            },
          },
          {
            value: item.ID_Direttore,
          },
        ];
      case TypeOperationEnum.DIREZIONE:
        return [
          {
            value: item.Titolo,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, true);
            },
          },
          {
            value:
              item.published === '1'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      case TypeOperationEnum.STRUTTURE:
        return [
          {
            value: item.Nome_Struttura,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, true);
            },
          },
          {
            value:
              item.published === '1'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      case TypeOperationEnum.DIPARTIMENTI_UNITA_OPERATIVE:
        return [
          {
            value: item.Nome_Struttura,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, true);
            },
          },
          {
            value: item.ID_Direttore,
          },
        ];
      case TypeOperationEnum.NEWS:
        return [
          {
            value: item.post_title,
            handlerFunction: () => {
              this.readDocument(this.typologyName, item, true, false);
            },
          },
          {
            value: this.dates.transformDate(item.post_date, 'dd/MM/yyyy'),
          },
          {
            value: this.dates.transformDate(item.post_expire_date, 'dd/MM/yyyy'),
          },
          {
            value:
              item.post_status === 'publish'
                ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
          },
        ];
      default:
        if (this.otherCategoryEnable || this.otherSubCategoryEnable) {
          return [
            {
              value: this.otherCategoryEnable ? item.NomeDocumento : item.Nome,
              handlerFunction: () => {
                this.readDocument(this.typologyName, item, true, false);
              },
            },
            {
              value: this.otherSubCategoryEnable
                ? this.filterSubCategory.Nome
                : this.otherCategoryEnable
                ? this.filterCategory.Nome
                : this.getNameTitle(this.documentTypeName),
            },
            {
              value:
                item.published === '1'
                  ? this.translate.instant('USER_MANAGEMENT.PUBLISHED')
                  : this.translate.instant('USER_MANAGEMENT.NOT_PUBLISHED'),
            },
          ];
        }
        break;
    }
  }

  getActiveStatus(tipoPubblicazione: string) {
    return tipoPubblicazione !== '' && tipoPubblicazione.includes('1')
      ? this.translate.instant('USER_MANAGEMENT.ACTIVE')
      : this.translate.instant('USER_MANAGEMENT.NOT_ACTIVE');
  }
  isDisabledUser(element) {
    return (
      this.getActiveStatus(element.tipo_pubblicazione) ===
      this.translate.instant('USER_MANAGEMENT.NOT_ACTIVE')
    );
  }
}
