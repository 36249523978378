export enum TypeOperationEnum {
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  BANDO = 'BANDO',
  CONCORSI = 'CONCORSI',
  AVVISI = 'AVVISO',
  ATTI = 'ATTI',
  SINISTRO = 'SINISTRO',
  EVENTI_AVVERSI = 'EVENTI AVVERSI',
  DETERMINA = 'DETERMINA',
  DELIBERA = 'DELIBERA',
  NEWS = 'NEWS',
  CIO = 'CIO',
  CAMPANIA_PANCREAS = 'CAMPANIA PANCREAS',
  COMITATO_ETICO = 'COMITATO ETICO',
  ATTI_DI_NOTIFICA = 'ATTI DI NOTIFICA',
  PRIVACY = 'PRIVACY',
  MANUALE_QUALITA = 'MANUALE DELLA QUALITA',
  PROCAZIENDALI = "PROCEDURE AZIENDALI",
  RLS = "RLS",
  ENDO = 'ENDO_PFD',
  RISK = 'RISK',
  HOME_CAROUSEL = 'HOME_CAROUSEL',
  DIREZIONE = 'DIREZIONE',
  STRUTTURE = 'STRUTTURE',
  DIPARTIMENTI = 'DIPARTIMENTI',
  DIPARTIMENTI_UNITA_OPERATIVE = 'DIPARTIMENTI_UNITA_OPERATIVE',
  // AMMINISTRAZIONE TRASPARENTE CATEGORIE
  BANDI_DI_CONCORSO = 'Bandi di concorso',
  ATTIVITÁ_E_PROCEDIMENTI = 'Attività e procedimenti',
  SERVIZI_EROGATI = 'Servizi erogati',
  DISPOSIZIONI_GENERALI = 'Disposizioni Generali',
  ALTRI_CONTENUTI = 'Altri contenuti',
  CONTROLLI_E_RILIEVI_AMMINISTRAZIONE = "Controlli e rilievi sull'amministrazione",
  BANDI_DI_GARA_E_CONTRATTI = 'Bandi di gara e Contratti',
  BENI_IMMOBILI_E_GESTIONE_PATRIMONIO = 'Beni immobili e gestione patrimonio',
  BILANCI = 'Bilanci',
  CONSULENTI_E_COLLABORATORI = 'Consulenti e collaboratori',
  CONTROLLI_SULLE_IMPRESE = 'Controlli sulle Imprese',
  ENTI_CONTROLLATI = 'Enti Controllati',
  INFORMAZIONI_AMBIENTALI = 'Informazioni ambientali',
  INTERVENTI_STRAORDINARI_E_DI_EMERGENZA = 'Interventi straordinari e di emergenza',
  OPERE_PUBBLICHE = 'Opere Pubbliche',
  ORGANIZZAZIONE = 'Organizzazione',
  PAGAMENTI_AMMINISTRAZIONE = "Pagamenti dell'amministrazione",
  PERFORMANCE = 'Performance',
  PERSONALE = 'Personale',
  PIANIFICAZIONE_E_GOVERNO_DEL_TERRITORIO = 'Pianificazione e governo del territorio',
  PROVVEDIMENTI = 'Provvedimenti',
  SOVVENZIONI_CONTRIBUTI_SUSSIDI_VANTAGGI_ECONOMICI = 'Sovvenzioni, contributi, sussidi, vantaggi economici',
  UOC = 'UOC',
}

/*
    Privacy ?!

    0000000000001 	Bandi
    0000000000010	 Atti
    0000000000100	 Sinistri
    0000000001000	 Determine
    0000000010000	 Delibere
    0000000100000	 Eventi avversi
    0000001000000	 News
    0000010000000	 CIO
    0000100000000	 Campania Pancreas
    0001000000000	 Comitato Etico
    0010000000000	 Concorsi
    0100000000000	 Atti di notifica
    1111111111111  Admin
  */
