<!--start card-->
<div class="card-wrapper" *ngIf="data">
  <div class="card card-img no-after">
    <div class="img-responsive-wrapper">
      <div class="img-responsive">
        <figure class="img">
          <img
            (error)="onImgError($event)"
            *ngIf="data && data.post_image_url"
            src="{{ data.post_image_url }}"
            [ngStyle]="{ 'object-fit': cover }"
            alt="banner-news"
          />
        </figure>
      </div>
    </div>
    <div class="card-body">
      <p
        role="region"
        aria-label="data.post_title"
        class="card-title h5 news_Title"
        innerHTML="{{ data.post_title }}"
      >
        {{ data.post_title }}
      </p>
      <p
        role="region"
        aria-label="data.post_excerpt"
        class="card-text abstract"
        [innerHtml]="sanitizerHtml(data?.post_excerpt || '')"
        >
        {{ data.post_excerpt }}
      </p>
      <div class="d-flex">
        <div>
          <a
            role="navigation"
            aria-label="LEGGI DI PIÙ →"
            [routerLink]="[data.post_permalink]"
            class="leggi-di-piu"
            >{{ "BUTTON.READ_MORE" | translate }}</a
          >
        </div>
        <div class="card-calendar d-flex flex-column justify-content-center">
          <span role="region" aria-label="Giorno" class="card-day">{{
            data.post_date | date : "dd" : "" : "it"
          }}</span>

          <span role="region" aria-label="Mese" class="card-month">{{
            data.post_date | date : "MMMM" : "" : "it"
          }}</span>
          <span role="region" aria-label="Anno" class="card-day">{{
            data.post_date | date : "yyyy" : "" : "it"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end card-->
