/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {
  noticesDetailResponse,
  noticesDocumentsResponse,
  noticesPlaceResponse,
} from 'src/app/shared/messageType';

@Injectable({
  providedIn: 'root',
})
export class NoticesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation postSearchNoticesDetails
   */
  static readonly PostSearchNoticesDetailsPath = '/api/v1/notices/_search';
  static readonly PostSearchTitleNoticesDetailsPath =
    '/api/v1/notices/title_search';

  static readonly PutUpdatePublishPath = '/api/v1/notices/_update_publish';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchNoticesDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesDetails$Response(
    body?: {
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDetailResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostSearchNoticesDetailsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchNoticesDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesDetails(
    body?: {
      tipobando?: string;
      published?: string;
      filter?: string;
      titolo?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDetailResponse>;
  }> {
    return this.postSearchNoticesDetails$Response(body, params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }
      )
    );
  }

  postSearchTitleNoticesDetails$Response(
    body?: {
      published?: string;
      titolo?: string;
      tipobando?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDetailResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostSearchTitleNoticesDetailsPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchNoticesDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchTitleNoticesDetails(
    body?: {
      type?: number;
      published?: string;
      titolo?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDetailResponse>;
  }> {
    return this.postSearchTitleNoticesDetails$Response(body, params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchNoticesDocuments
   */
  static readonly PostSearchNoticesDocumentsPath =
    '/api/v1/notices/{notice_id}/documents/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchNoticesDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesDocuments$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDocumentsResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostSearchNoticesDocumentsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('notice_id', params.notice_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }>;
        })
      );
  }

  postSearchAvvisiDocuments$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDocumentsResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostSearchNoticesDocumentsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('alert_id', params.alert_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchNoticesDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesDocuments(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDocumentsResponse>;
  }> {
    return this.postSearchNoticesDocuments$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }
      )
    );
  }

  postSearchAvvisiDocuments(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDocumentsResponse>;
  }> {
    return this.postSearchAvvisiDocuments$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchNoticesFaqs
   */
  static readonly PostSearchNoticesFaqsPath =
    '/api/v1/notices/{notice_id}/faqs/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchNoticesFaqs()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesFaqs$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idbando?: number;
        domanda?: string;
        risposta?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostSearchNoticesFaqsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('notice_id', params.notice_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchNoticesFaqs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesFaqs(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idbando?: number;
      domanda?: string;
      risposta?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.postSearchNoticesFaqs$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchNoticesNews
   */
  static readonly PostSearchNoticesNewsPath =
    '/api/v1/notices/{notice_id}/news/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchNoticesNews()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesNews$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idbando?: number;
        titolo?: string;
        testobreve?: string;
        testo?: string;
        stato?: number;
        internetview?: number;
        data?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostSearchNoticesNewsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('notice_id', params.notice_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchNoticesNews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesNews(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idbando?: number;
      titolo?: string;
      testobreve?: string;
      testo?: string;
      stato?: number;
      internetview?: number;
      data?: string;
    }>;
  }> {
    return this.postSearchNoticesNews$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchNoticesDeadlines
   */
  static readonly PostSearchNoticesDeadlinesPath =
    '/api/v1/notices/{notice_id}/deadlines/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchNoticesDeadlines()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesDeadlines$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idbando?: number;
        descrizione?: string;
        data?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostSearchNoticesDeadlinesPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('notice_id', params.notice_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchNoticesDeadlines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchNoticesDeadlines(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idbando?: number;
      descrizione?: string;
      data?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.postSearchNoticesDeadlines$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getNoticesDetailsById
   */
  static readonly GetNoticesDetailsByIdPath = '/api/v1/notices/{notice_id}';

  static readonly GetAvvisiDetailsByIdPath = '/api/v1/alerts/{alert_id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNoticesDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesDetailsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDetailResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.GetNoticesDetailsByIdPath,
      'get'
    );
    if (params) {
      rb.path('notice_id', params.notice_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>;
        })
      );
  }

  getAvvisiDetailsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDetailResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.GetAvvisiDetailsByIdPath,
      'get'
    );
    if (params) {
      rb.path('alert_id', params.alert_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNoticesDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesDetailsById(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDetailResponse>;
  }> {
    return this.getNoticesDetailsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }
      )
    );
  }

  getAvvisiDetailsById(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDetailResponse>;
  }> {
    return this.getAvvisiDetailsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }
      )
    );
  }

  /**
   * Path part for operation getNoticesDocumentsById
   */
  static readonly GetNoticesDocumentsByIdPath =
    '/api/v1/notices/{notice_id}/documents/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNoticesDocumentsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesDocumentsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idbando?: number;
        titolo?: string;
        datains?: string;
        descrizione?: string;
        docfile?: string;
        internetview?: number;
        stato?: number;
        tipodoc?: number;
        filename?: string;
        docfile1?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.GetNoticesDocumentsByIdPath,
      'get'
    );
    if (params) {
      rb.path('notice_id', params.notice_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              datains?: string;
              descrizione?: string;
              docfile?: string;
              internetview?: number;
              stato?: number;
              tipodoc?: number;
              filename?: string;
              docfile1?: string;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNoticesDocumentsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesDocumentsById(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idbando?: number;
      titolo?: string;
      datains?: string;
      descrizione?: string;
      docfile?: string;
      internetview?: number;
      stato?: number;
      tipodoc?: number;
      filename?: string;
      docfile1?: string;
    }>;
  }> {
    return this.getNoticesDocumentsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              datains?: string;
              descrizione?: string;
              docfile?: string;
              internetview?: number;
              stato?: number;
              tipodoc?: number;
              filename?: string;
              docfile1?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              datains?: string;
              descrizione?: string;
              docfile?: string;
              internetview?: number;
              stato?: number;
              tipodoc?: number;
              filename?: string;
              docfile1?: string;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getNoticesFaqsById
   */
  static readonly GetNoticesFaqsByIdPath =
    '/api/v1/notices/{notice_id}/faqs/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNoticesFaqsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesFaqsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idbando?: number;
        domanda?: string;
        risposta?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.GetNoticesFaqsByIdPath,
      'get'
    );
    if (params) {
      rb.path('notice_id', params.notice_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNoticesFaqsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesFaqsById(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idbando?: number;
      domanda?: string;
      risposta?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.getNoticesFaqsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getNoticesNewsById
   */
  static readonly GetNoticesNewsByIdPath =
    '/api/v1/notices/{notice_id}/news/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNoticesNewsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesNewsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idbando?: number;
        titolo?: string;
        testobreve?: string;
        testo?: string;
        stato?: number;
        internetview?: number;
        data?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.GetNoticesNewsByIdPath,
      'get'
    );
    if (params) {
      rb.path('notice_id', params.notice_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNoticesNewsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesNewsById(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idbando?: number;
      titolo?: string;
      testobreve?: string;
      testo?: string;
      stato?: number;
      internetview?: number;
      data?: string;
    }>;
  }> {
    return this.getNoticesNewsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getNoticesDeadlinesById
   */
  static readonly GetNoticesDeadlinesByIdPath =
    '/api/v1/notices/{notice_id}/deadlines/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNoticesDeadlinesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesDeadlinesById$Response(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idbando?: number;
        descrizione?: string;
        data?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.GetNoticesDeadlinesByIdPath,
      'get'
    );
    if (params) {
      rb.path('notice_id', params.notice_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNoticesDeadlinesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNoticesDeadlinesById(params: {
    /**
     * Internal ID for identification.
     */
    notice_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idbando?: number;
      descrizione?: string;
      data?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.getNoticesDeadlinesById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idbando?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  static readonly PostNoticesPlacePath = '/api/v1/notices_place';

  /**

  */
  PostNoticesPlace$Response(
    ID_ORG,
    Bando_ID
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesPlaceResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PostNoticesPlacePath,
      'post'
    );
    if (ID_ORG) {
      rb.body({ ID_ORG: ID_ORG, Bando_ID: Bando_ID }, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesPlaceResponse>;
          }>;
        })
      );
  }

  /**

   */
  PostNoticesPlace(
    ID_ORG,
    Bando_ID
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesPlaceResponse>;
  }> {
    return this.PostNoticesPlace$Response(ID_ORG, Bando_ID).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesPlaceResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesPlaceResponse>;
          }
      )
    );
  }

  /*
   * UPDATE PUBLISH
   */
  UpdatePublishNotices$Response(params?: {
    ID: string;
    published: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NoticesService.PutUpdatePublishPath,
      'put'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  UpdatePublishNotices(params?: {
    ID: string;
    published: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.UpdatePublishNotices$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }
}
