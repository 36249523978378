import { Injectable, SecurityContext } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  Validators,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { DepartmentsService, NoticesService } from '../../../api/services';
import { AttiNotificaService } from '../../../api/services/atti-notifica.service';
import { CampaniaPancreasService } from '../../../api/services/campania-pancreas.service';
import { CioService } from '../../../api/services/cio.service';
import { DownloadService } from '../../../api/services/download.service';
import { EthicsService } from '../../../api/services/ethics.service';
import { LoaderService } from '../../../api/services/loader.service';
import { ManagementService } from '../../../api/services/management.service';
import { GenericInfoModalService } from '../../../components/generic-info-modal/generic-info-modal.service';
import { ModalModeType } from '../../../shared/enums/modalModeType';
import { RoleUserEnum } from '../../../shared/enums/roleUsersEnum';

import { ADMIN_VALUE, GLOBAL_BIT_NUM } from '../../../shared/models/constants';
import * as moment from 'moment';
import { PrivacyService } from '../../../api/services/privacy.service';
import { RiskService } from '../../../api/services/risk.service';
import { TypeOperationEnum } from '../../../shared/enums/typeOperationEnum';
import { DocumentPostType } from '../../../shared/enums/documentPostTypeEnum';
import { CompanyService } from '../../../api/services/company.service';
import { GenericInsert } from '../../../shared/messageType';
import { NoticesDetailInsert } from '../../../shared/messageType';
import { StructureTypeEnum } from '../../../shared/enums/structureTypeEnum';
import { DomSanitizer } from '@angular/platform-browser';
import { HomeService } from 'src/app/api/services/home.service';
import { ProcedureService } from 'src/app/api/services/procedure.service';
import { RlsService } from 'src/app/api/services/rls.service';
import { ManualeQualitaService } from 'src/app/api/services/manualequalita.service';
import { EndoService } from 'src/app/api/services/endo.service';
import { AvvisiService } from 'src/app/api/services/avvisi.service';

declare var $: any;
@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  fileUploadedResult$: Observable<any>;
  private fileUploadedSubject = new Subject<any>();

  loaderPage = false;

  constructor(
    private loaderService: LoaderService,
    private downloadService: DownloadService,
    private translate: TranslateService,
    private infoModal: GenericInfoModalService,
    private managementService: ManagementService,
    private campaniaPancreasService: CampaniaPancreasService,
    private cioService: CioService,
    private procedureService:ProcedureService,
    private manualeService:ManualeQualitaService,
    private rlsService:RlsService,
    private endoService:EndoService,
    private comitatoEticoService: EthicsService,
    private noticesService: NoticesService,
    private avvisiService: AvvisiService,
    private attiNotificaService: AttiNotificaService,
    private privacyService: PrivacyService,
    private riskService: RiskService,
    private companyManagementService: CompanyService,
    private departmentService: DepartmentsService,
    private homeService: HomeService,
    private _sanitizer: DomSanitizer
  ) {
    this.fileUploadedResult$ = this.fileUploadedSubject.asObservable();
  }

  /**
   * UTILITIES
   */
  createSubForm(
    role: RoleUserEnum,
    fb: FormBuilder,
    isReadOnly: boolean,
    controls: {
      key: string;
      title: string;
      controlType: string;
      required: boolean;
      disabled: boolean;
    }[]
  ): FormGroup {
    const controlsObject = {};
    controls.forEach((control) => {
      if (control.key === 'mail') {
        controlsObject[control.key] = [
          null,
          control.required
            ? [
                Validators.required,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
              ]
            : null,
        ];
      } else if (control.key === 'Link') {
        controlsObject[control.key] = [
          null,
          control.required
            ? [
                Validators.required,
                Validators.pattern(
                  /^(https?|ftp):\/\/([^\s\/\?#]+\.?)+(\/[^\s]*)?$/
                ),
              ]
            : null,
        ];
      } else {
        controlsObject[control.key] = [
          null,
          control.required ? Validators.required : null,
        ];
      }
    });
    const group = fb.group(controlsObject);
    controls.forEach((control) => {
      group.controls[control.key]['name'] = control.key;
      group.controls[control.key]['type'] = control.controlType;
      group.controls[control.key]['title'] = control.title;
      group.controls[control.key]['required'] = control.required;
    });
    isReadOnly ? group.disable() : group.enable();
    if (role === RoleUserEnum.AUTHOR) {
      group.controls['publish'].disable();
    }

    return group;
  }

  getMinDate(
    attributes: { controls: { [x: string]: FormGroup } },
    control: { name: string },
    index: string | number
  ) {
    if (control.name === 'end_date') {
      if ((attributes.controls[index] as FormGroup).controls.start_date.value) {
        return (attributes.controls[index] as FormGroup).controls.start_date
          .value;
      } else {
        return '';
      }
    }
    return '';
  }

  getMaxLength(control: { name: string }, index: any) {
    if (control.name === 'description') {
      return 250;
    }
    if (control.name === 'title') {
      return 150;
    }
    if (control.name === 'long_desc') {
      return 500;
    }
    if (control.name === 'short_desc') {
      return 150;
    }
    if (control.name === 'description1' || control.name === 'description2') {
      return 20;
    }
    return 500;
  }

  getMailIsEmpty(
    attributes: { controls: { [key: string]: FormGroup } },
    index: string | number
  ) {
    return !(
      (attributes.controls[index] as FormGroup).controls.mail.value !== ''
    );
  }

  getPathlinkIsEmpty(
    attributes: { controls: { [key: string]: FormGroup } },
    index: string | number
  ) {
    return !(
      (attributes.controls[index] as FormGroup).controls.Link.value !== ''
    );
  }

  getMailIsValid(
    attributes: { controls: { [key: string]: FormGroup } },
    index: string | number
  ) {
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return expression.test(
      (attributes.controls[index] as FormGroup).controls.mail.value
    );
  }

  getPathlinkIsValid(
    attributes: { controls: { [key: string]: FormGroup } },
    index: string | number
  ) {
    const expression: RegExp =
      /^(https?|ftp):\/\/([^\s\/\?#]+\.?)+(\/[^\s]*)?$/;

    return expression.test(
      (attributes.controls[index] as FormGroup).controls.Link.value
    );
  }

  isInputDisabled(
    attributes: { controls: { [x: string]: FormGroup } },
    control: { name: string },
    index: string | number
  ) {
    return (
      control.name === 'end_date' &&
      !(attributes.controls[index] as FormGroup).controls.start_date.value
    );
  }

  showCounter(control: { name: string }) {
    if (
      control.name === 'description' ||
      control.name === 'title' ||
      control.name === 'long_desc' ||
      control.name === 'short_desc' ||
      control.name === 'description1' ||
      control.name === 'description2'
    ) {
      return true;
    }
    return false;
  }

  getDocumentName(doc: {
    filename: any;
    name: any;
    NomeFile: any;
    Nomefile: any;
    NomeDocumentoFile: any;
  }) {
    return doc.filename
      ? doc.filename
      : doc.name
      ? doc.name
      : doc.NomeFile
      ? doc.NomeFile
      : doc.Nomefile
      ? doc.Nomefile
      : doc.NomeDocumentoFile
      ? doc.NomeDocumentoFile
      : this.translate.instant('DOCUMENTS.UNTITLED');
  }

  didSelectAttachment(
    element,
    modalMode: ModalModeType,
    nomePubblicazione: any
  ) {
    if (
      nomePubblicazione === TypeOperationEnum.HOME_CAROUSEL ||
      nomePubblicazione === TypeOperationEnum.DIPARTIMENTI
    ) {
      var image = new Image();
      image.src = element.imagePath;
      var w = window.open('', '_blank');
      w.document.write(image.outerHTML);
    } else if (
      (modalMode === ModalModeType.EDIT ||
        modalMode === ModalModeType.READ_ONLY) &&
      (element.ID || element.ID_Generico)
    ) {
      let fileName = '';
      let postType = '';
      let postID = '';
      let parentCodeDoc: string;
      switch (nomePubblicazione) {
        case TypeOperationEnum.BANDO:
        case TypeOperationEnum.CONCORSI:
          postType = DocumentPostType.NOTICES;
          postID = element.ID ? element.ID.toString() : '';
          fileName = element.filename;
          break;
        case TypeOperationEnum.AVVISI:
          postType = DocumentPostType.AVVISI;
          postID = element.ID ? element.ID.toString() : '';
          fileName = element.filename;
          break;
        case TypeOperationEnum.CAMPANIA_PANCREAS:
          postType = DocumentPostType.CAM_PANCREAS;
          postID = element.ID;
          fileName = element.NomeFile;
          break;
        case TypeOperationEnum.COMITATO_ETICO:
          postType = DocumentPostType.COM_ETIC;
          postID = element.ID;
          fileName = element.NomeFile;
          break;
        case TypeOperationEnum.RISK:
          postType = DocumentPostType.RISK_MANAGER;
          postID = element.ID;
          fileName = element.NomeFile;
          break;
        case TypeOperationEnum.CIO:
          postType = DocumentPostType.CIO_COM;
          postID = element.ID;
          fileName = element.NomeFile;
          break;
        case TypeOperationEnum.ATTI_DI_NOTIFICA:
          postType = DocumentPostType.ATTI_NOT;
          postID = element.ID;
          fileName = element.NomeFile;
          break;
        case TypeOperationEnum.PRIVACY:
          postType = DocumentPostType.PRIVACY;
          postID = element.ID;
          fileName = element.NomeFile;
          break;
        case TypeOperationEnum.ATTI:
        default:
          postType = DocumentPostType.GENERICS;
          postID = element.ID_Generico;
          fileName = element.NomeFile;
          parentCodeDoc = element.CodicePadreDocumento
            ? element.CodicePadreDocumento.toString()
            : undefined;
          break;
      }
      const url = parentCodeDoc
        ? this.downloadService.rootUrl +
          DownloadService.PostDownload1Path +
          postType +
          DownloadService.PostDownload2Path +
          parentCodeDoc +
          '/' +
          postID
        : this.downloadService.rootUrl +
          DownloadService.PostDownload1Path +
          postType +
          DownloadService.PostDownload2Path +
          postType +
          '/' +
          postID;
      this.downloadService.downloadBlob(fileName, url);
    }
  }

  /**
   *  GENERICS UTILITIES
   */

  initFormGenerics(
    element: {
      NomeDocumento: any;
      DataIns: moment.MomentInput;
      published: string;
      documents: any;
      NomeFile: any;
    },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean,
    selectedTypeDocument: { nome_pubblicazione: TypeOperationEnum; Nome: any }
  ) {
    documentForm.controls.type.setValue(selectedTypeDocument.Nome);

    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.NomeDocumento || '' : ''
    );

    (attributes.controls[0] as FormGroup).controls.dataIns.setValue(
      element ? moment(element.DataIns).format('YYYY-MM-DD') || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );

    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: {
          ID_Generico: { toString: () => any };
          CodicePadreDocumento: { toString: () => any };
          docfile: any;
        }) => {
          fileUploading = false;
          const postTypeNotices = 'generics';
          const postId = file.ID_Generico ? file.ID_Generico.toString() : '';
          const parentCodeDoc = file.CodicePadreDocumento
            ? file.CodicePadreDocumento.toString()
            : undefined;
          this.downloadService
            .Download({
              post_type: postTypeNotices,
              post_id: postId,
              post_cod_padre_doc: parentCodeDoc,
            })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  deleteGenerics(element: { ID_Generico: any }, actionFunction: () => void) {
    this.managementService.DeleteGeneric({ ID: element.ID_Generico }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_DELETE'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_DELETE'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }
  callGenericInsert(
    requestBody: GenericInsert,
    modalMode: string,
    actionFunction: () => void
  ) {
    this.managementService.PostGenericInsert(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callGenericUpdate(requestBody: GenericInsert, actionFunction: () => void) {
    this.managementService.PutGenericUpdate(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  checkAreFilesIntoGenerics(obj: { Files: string | any[] }) {
    return obj && obj.Files && obj.Files.length > 0;
  }

  getCodicePadreDocumentoFromFiles(obj: {
    Files: { CodicePadreDocumento: any }[];
  }) {
    if (this.checkAreFilesIntoGenerics(obj)) {
      return obj.Files[0].CodicePadreDocumento;
    }
  }

  getSubLinkFromFiles(obj: { Files: { SubLink: any }[] }) {
    if (this.checkAreFilesIntoGenerics(obj)) {
      return obj.Files[0].SubLink;
    }
  }

  hasSubdirectoryFromFiles(obj: { Files: { subdirectory: any }[] }) {
    if (this.checkAreFilesIntoGenerics(obj)) {
      return obj.Files[0].subdirectory === '1';
    }
    return false;
  }

  changeStatusGenerics(ID: any, published: string, actionFunction: () => void) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.managementService.UpdatePublisGenerics({ ID, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   *  CAMPANIA PANCREAS UTILITIES
   */
  initFormCampaniaPancreas(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.CAMPANIA_PANCREAS);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.CAM_PANCREAS;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  callCampaniaPancreasInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.campaniaPancreasService
      .postInsertCampaniaPancreas(requestBody)
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  callCampaniaPancreasUpdate(
    requestBody: {
      cam_id: string;
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.campaniaPancreasService.updateCampaniaPancreas(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusCampaniaPancreas(
    cam_id: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.campaniaPancreasService
      .UpdatePublishCampaniaPancreas({ cam_id, published })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);

            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  /**
   * RISK MANAGER
   */

  initFormRisk(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.RISK);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.RISK_MANAGER;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  callRiskInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.riskService.postInsertRisk(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusRisk(
    risk_id: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.riskService.UpdatePublishRisk({ risk_id, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callRiskUpdate(requestBody: any, actionFunction: () => void) {
    this.riskService.postUpdateRisk(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * COMITATO ETICO
   */

  initFormComitatoEtico(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.COMITATO_ETICO);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.RISK_MANAGER;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  callComitatoEticoInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.comitatoEticoService.postInsertEthicsCommitee(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusComitatoEtico(
    etic_id: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.comitatoEticoService
      .UpdatePublishEthicsCommitee({ etic_id, published })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);

            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  callComitatoEticoUpdate(requestBody: any, actionFunction: () => void) {
    this.comitatoEticoService.postUpdateEthicsCommitee(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callProcedureInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.procedureService.postInsertProcAziend(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusProcedure(
    prod_az_id: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.procedureService
      .UpdatePublishProcAziend({ prod_az_id, published })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);

            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  callProcedureUpdate(requestBody: any, actionFunction: () => void) {
    this.procedureService.postUpdateProcAziend(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * PRIVACY
   */
  initFormPrivacy(
    privacyList: any[],
    element: {
      Categoria: any;
      Titolo: any;
      published: string;
      documents: any;
      NomeFile: any;
    },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    var category = '';
    if (element) {
      const index = privacyList.findIndex(
        (elem: { Nome: any }) => elem.Nome === element.Categoria
      );
      category = index != null ? privacyList[index].ID : '';
    }

    documentForm.controls.type.setValue(TypeOperationEnum.PRIVACY);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.category.setValue(category);
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.PRIVACY;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  callPrivacyInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.privacyService.postInsertPrivacy(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callPrivacyUpdate(
    requestBody: {
      privacy_id: string;
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.privacyService.putUpdatePrivacy(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callRlsUpdate(
    requestBody: {
      rls_id: string;
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.rlsService.putUpdateRls(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callEndoUpdate(
    requestBody: {
      endo_pfd_id: string;
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.endoService.putUpdateEndo(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callManQualitaUpdate(
    requestBody: {
      manual_qa_id: string;
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.manualeService.putUpdateManQualita(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusPrivacy(
    privacy_id: string,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.privacyService
      .UpdatePublishPrivacy({ privacy_id, published })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);

            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  /**
   * CIO
   */
  initFormCIO(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.CIO);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.CIO_COM;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  initFormProcedure(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.PROCAZIENDALI);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.PROCAZIENDALI;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  initFormManQualita(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.MANUALE_QUALITA);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.MANUALE_QUALITA;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  initFormRls(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.RLS);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.PROCAZIENDALI;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  initFormEndo(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.ENDO);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.ENDO;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  initFormAvvisi(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.ENDO);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.AVVISI;
          const postId = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postId })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  callCioInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.cioService.postInsertCio(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callRlsInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.rlsService.postInsertRls(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callEndoInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.endoService.postInsertEndo(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  // callAvvisiInsert(
  //   requestBody: {
  //     Titolo?: string;
  //     File?: string;
  //     published?: string;
  //     NomeFile?: string;
  //   },
  //   actionFunction: () => void
  // ) {
  //   this.avvisiService.postInsertAvvisi(requestBody).subscribe(
  //     (res) => {
  //       if (res && res.rowCount > 0) {
  //         this.loaderPage = false;
  //         this.loaderService.setloader(this.loaderPage);
  //         this.infoModal.setModal({
  //           show: true,
  //           type: 'success',
  //           title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
  //           bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
  //           actionLabel: this.translate.instant('BUTTON.OK'),
  //           actionFunction: function () {
  //             actionFunction();
  //           }.bind(this),
  //         });
  //       } else {
  //         this.infoModal.setModal({
  //           show: true,
  //           type: 'error',
  //           title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
  //           bodyText:
  //             res.message ||
  //             this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
  //           actionLabel: this.translate.instant('BUTTON.OK'),
  //           actionFunction: () => {},
  //         });
  //         this.loaderPage = false;
  //         this.loaderService.setloader(this.loaderPage);
  //       }
  //     },
  //     (err) => {
  //       this.loaderPage = false;
  //       this.loaderService.setloader(this.loaderPage);
  //       this.checkTokenExpired(err);
  //     }
  //   );
  // }

  callManQualitaInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.manualeService.postInsertManQualita(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callCioUpdate(
    requestBody: {
      cio_id: string;
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.cioService.putUpdatetCio(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusCIO(
    cio_id: string,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.cioService.UpdatePublishCio({ cio_id, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusRls(
    rls_id: string,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.rlsService.UpdatePublishRls({ rls_id, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusEndo(
    endo_pfd_id: string,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.endoService.UpdatePublishEndo({ endo_pfd_id, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusManQualita(
    manual_qa_id: string,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.manualeService.UpdatePublishManQualita({ manual_qa_id, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * ATTI NOTIFICA
   */
  initFormAttiNotifica(
    element: { Titolo: any; published: string; documents: any; NomeFile: any },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[],
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.ATTI_DI_NOTIFICA);
    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    if (selectedRow) {
      selectedRow.documents = [];
      selectedRow.documents.push(element);
    }

    filesUploaded = element ? element.documents : [];
    fileUploading = element && element.NomeFile ? true : false;
    if (filesUploaded.length > 0) {
      (attributes.controls[0] as FormGroup).controls.documents.setValue(
        element ? element.documents || '' : ''
      );
      filesUploaded.forEach(
        (file: { ID: { toString: () => any }; docfile: any }) => {
          fileUploading = false;
          const postType = DocumentPostType.ATTI_NOT;
          const postIdAttiNotifica = file.ID ? file.ID.toString() : '';
          this.downloadService
            .Download({ post_type: postType, post_id: postIdAttiNotifica })
            .subscribe((res) => {
              file.docfile = res;
              this.fileUploadedSubject.next(filesUploaded);
            });

          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      );
    }
  }

  callAttiNotificaInsert(
    requestBody: {
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.attiNotificaService.postInsertAttiNotifica(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callAttiNotificaUpdate(
    requestBody: {
      atti_id: string;
      Titolo?: string;
      File?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.attiNotificaService.putUpdateAttiNotifica(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusAttiNotifica(
    atti_id: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.attiNotificaService
      .UpdatePublishAttiNotifica({ atti_id, published })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);

            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }
  /**
   * HOME
   */

  initFormHomeDocumental(
    element: {
      Titolo: any;
      Link: string;
      Source: string;
      published: string;
      picture: any;
    },
    documentForm: FormGroup,
    attributes: FormArray,
    filesUploaded: any[] | File,
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.HOME_CAROUSEL);

    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.Link.setValue(
      element ? element.Link || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    (attributes.controls[0] as FormGroup).controls.picture.setValue(
      element ? element.Source || '' : ''
    );

    if (element && element.Source) {
      const imagePath = this._sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this._sanitizer.bypassSecurityTrustResourceUrl(element.Source)
      );
      filesUploaded = [
        new File([this.b64toBlob(imagePath)], 'Banner', {
          type: 'image/jpeg',
        }),
      ];
      fileUploading = filesUploaded && element.Source ? true : false;
      if (filesUploaded.length > 0) {
        filesUploaded[0].imagePath = imagePath;
        setTimeout(() => {
          this.fileUploadedSubject.next(filesUploaded);
        }, 500);
      }
    }
  }

  callHomeInsert(
    requestBody: {
      Titolo?: string;
      Source?: string;
      Link?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.homeService.postInsertHome(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant(
              'DOCUMENTS.GENERIC_SUCCESS_HEADER_CREATE'
            ),
            bodyText: this.translate.instant(
              'DOCUMENTS.GENERIC_SUCCESS_BODY_CREATE'
            ),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callHomeUpdate(
    requestBody: {
      ID: string;
      Titolo?: string;
      Source?: string;
      Link?: string;
      published?: string;
      NomeFile?: string;
    },
    actionFunction: () => void
  ) {
    this.homeService.putUpdateHome(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant(
              'DOCUMENTS.GENERIC_SUCCESS_HEADER_EDIT'
            ),
            bodyText: this.translate.instant(
              'DOCUMENTS.GENERIC_SUCCESS_BODY_EDIT'
            ),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusHome(ID: any, published: string, actionFunction: () => void) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.homeService.updatePublishHome({ ID, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * NOTICES
   */

  changeStatusNotices(ID: any, published: string, actionFunction: () => void) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.noticesService.UpdatePublishNotices({ ID, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  changeStatusAvvisi(ID: any, published: string, actionFunction: () => void) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.avvisiService.UpdatePublishAvvisi({ ID, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  deleteNotices(ID: { toString: () => any }, actionFunction: () => void) {
    this.managementService
      .DeleteNotices({ notice_id: ID.toString() })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_DELETE'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_DELETE'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }


  deleteAvvisi(ID: { toString: () => any }, actionFunction: () => void) {
    this.managementService
      .DeleteAvvisi({ alert_id: ID.toString() })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_DELETE'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_DELETE'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  deleteNoticesDocument(
    ID: { toString: () => any },
    actionFunction: () => void
  ) {
    this.managementService
      .DeleteNoticesDocument(ID, { idBando: ID.toString() })
      .subscribe(
        (result) => {
          if (result && result.rowCount > 0) {
            this.managementService
              .DeleteNotices({ notice_id: ID.toString() })
              .subscribe(
                (res) => {
                  if (res && res.rowCount > 0) {
                    this.loaderPage = false;
                    this.loaderService.setloader(this.loaderPage);
                    this.infoModal.setModal({
                      show: true,
                      type: 'success',
                      title: this.translate.instant(
                        'DOCUMENTS.SUCCESS_HEADER_DELETE'
                      ),
                      bodyText: this.translate.instant(
                        'DOCUMENTS.SUCCESS_BODY_DELETE'
                      ),
                      actionLabel: this.translate.instant('BUTTON.OK'),
                      actionFunction: function () {
                        actionFunction();
                      }.bind(this),
                    });
                  } else {
                    this.infoModal.setModal({
                      show: true,
                      type: 'error',
                      title: this.translate.instant(
                        'ALERT_MESSAGE.TITLE_ERROR'
                      ),
                      bodyText:
                        res.message ||
                        this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
                      actionLabel: this.translate.instant('BUTTON.OK'),
                      actionFunction: () => {},
                    });
                    this.loaderPage = false;
                    this.loaderService.setloader(this.loaderPage);
                  }
                },
                (err) => {
                  this.loaderPage = false;
                  this.loaderService.setloader(this.loaderPage);
                  this.checkTokenExpired(err);
                }
              );
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                result.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  deleteAvvisiDocument(
    ID: { toString: () => any },
    actionFunction: () => void
  ) {
    this.managementService
      .DeleteAvvisiDocument(ID, { idAvviso: ID.toString() })
      .subscribe(
        (result) => {
          if (result && result.rowCount > 0) {
            this.managementService
              .DeleteAvvisi({ alert_id: ID.toString() })
              .subscribe(
                (res) => {
                  if (res && res.rowCount > 0) {
                    this.loaderPage = false;
                    this.loaderService.setloader(this.loaderPage);
                    this.infoModal.setModal({
                      show: true,
                      type: 'success',
                      title: this.translate.instant(
                        'DOCUMENTS.SUCCESS_HEADER_DELETE'
                      ),
                      bodyText: this.translate.instant(
                        'DOCUMENTS.SUCCESS_BODY_DELETE'
                      ),
                      actionLabel: this.translate.instant('BUTTON.OK'),
                      actionFunction: function () {
                        actionFunction();
                      }.bind(this),
                    });
                  } else {
                    this.infoModal.setModal({
                      show: true,
                      type: 'error',
                      title: this.translate.instant(
                        'ALERT_MESSAGE.TITLE_ERROR'
                      ),
                      bodyText:
                        res.message ||
                        this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
                      actionLabel: this.translate.instant('BUTTON.OK'),
                      actionFunction: () => {},
                    });
                    this.loaderPage = false;
                    this.loaderService.setloader(this.loaderPage);
                  }
                },
                (err) => {
                  this.loaderPage = false;
                  this.loaderService.setloader(this.loaderPage);
                  this.checkTokenExpired(err);
                }
              );
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                result.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  callNoticesUpdate(
    requestBody: NoticesDetailInsert,
    actionFunction: () => void
  ) {
    this.managementService.PutNoticesUpdate(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callAvvisiUpdate(
    requestBody: NoticesDetailInsert,
    actionFunction: () => void
  ) {
    this.managementService.PutAvvisiUpdate(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * DIREZIONE
   */
  initFormCompanyManagement(
    element: { Titolo: any; published: string },
    documentForm: FormGroup,
    attributes: FormArray
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.DIREZIONE);

    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
  }

  changeStatusDirezione(
    ID: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.companyManagementService
      .companyPutPublished({ ID, published })
      .subscribe(
        (res) => {
          if (res && res.rowCount > 0) {
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);

            this.infoModal.setModal({
              show: true,
              type: 'success',
              title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
              bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: function () {
                actionFunction();
              }.bind(this),
            });
          } else {
            this.infoModal.setModal({
              show: true,
              type: 'error',
              title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
              bodyText:
                res.message ||
                this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
              actionLabel: this.translate.instant('BUTTON.OK'),
              actionFunction: () => {},
            });
            this.loaderPage = false;
            this.loaderService.setloader(this.loaderPage);
          }
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.checkTokenExpired(err);
        }
      );
  }

  callDirezioneInsert(
    requestBody: {
      ID: string;
      Nome_Struttura: string;
      Luogo_Struttura: string;
      ID_Direttore: string;
      ID_Referente: string;
      Info_Generale: string;
      Descrizione: string;
      LastUpdate: string;
      Tipologia_Struttura: string;
      published: string;
      ID_Direzione: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.companyManagementService.companyInsert(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.COMPANY_SUCCESS_HEADER_CREATE'
                  )
                : this.translate.instant(
                    'COMPANY_DOCUMENTAL.COMPANY_SUCCESS_HEADER_EDIT'
                  ),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.COMPANY_SUCCESS_BODY_CREATE'
                  )
                : this.translate.instant(
                    'COMPANY_DOCUMENTAL.COMPANY_SUCCESS_BODY_EDIT'
                  ),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callDirezioneUpdate(
    requestBody: {
      ID: string;
      Nome_Struttura: string;
      Luogo_Struttura: string;
      ID_Direttore: string;
      ID_Referente: string;
      Info_Generale: string;
      Descrizione: string;
      LastUpdate: string;
      Tipologia_Struttura: string;
      published: string;
      ID_Direzione: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.companyManagementService.companyUpdate(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.EDIT_MODAL_DIRECTION_TITLE'
                  )
                : this.translate.instant(
                    'COMPANY_DOCUMENTAL.EDIT_MODAL_DIRECTION_TITLE'
                  ),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.COMPANY_SUCCESS_BODY_EDIT'
                  )
                : this.translate.instant(
                    'COMPANY_DOCUMENTAL.COMPANY_SUCCESS_BODY_EDIT'
                  ),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * STRUTTURE
   */
  initFormStructures(
    element: { Nome_Struttura: any; published: string },
    documentForm: FormGroup,
    attributes: FormArray
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.STRUTTURE);

    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Nome_Struttura || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
  }

  changeStatusStructure(
    ID: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.companyManagementService.structurePublish({ ID, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callStructureInsert(
    requestBody: {
      ID: string;
      Nome_Struttura: string;
      Luogo_Struttura: string;
      ID_Direttore: string;
      ID_Referente: string;
      Info_Generale: string;
      Descrizione: string;
      LastUpdate: string;
      Tipologia_Struttura: string;
      published: string;
      ID_Direzione: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.companyManagementService.structureInsert(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_ADD_TITLE'
                  )
                : this.translate.instant(
                    'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_ADD_TITLE'
                  ),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_ADD_BODY'
                  )
                : this.translate.instant('DOCUMENTS.GENERIC_SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callStructureUpdate(
    requestBody: {
      ID: string;
      Nome_Struttura: string;
      Luogo_Struttura: string;
      ID_Direttore: string;
      ID_Referente: string;
      Info_Generale: string;
      Descrizione: string;
      LastUpdate: string;
      Tipologia_Struttura: string;
      published: string;
      ID_Direzione: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.companyManagementService.structureUpdate(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_EDIT_TITLE'
                  )
                : this.translate.instant(
                    'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_EDIT_TITLE'
                  ),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant(
                    'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_EDIT_BODY'
                  )
                : this.translate.instant(
                    'COMPANY_DOCUMENTAL.STRUCTURES_SUCCESS_EDIT_BODY'
                  ),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * DEPARTMENTS
   */

  initFormDepartments(
    element: {
      Titolo: string;
      Nome_Direttore: string;
      Email_Direttore: string;
      Luogo_Dipartimento: string;
      CV_Direttore: string;
      published: string;
      Foto_Direttore: any;
    },
    documentForm: FormGroup,
    attributes: FormArray,
    selectedRow: { documents: any[] },
    filesUploaded: any[] | File,
    fileUploading: boolean
  ) {
    documentForm.controls.type.setValue(TypeOperationEnum.DIPARTIMENTI);

    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Titolo || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.doctor.setValue(
      element ? element.Nome_Direttore || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.mail.setValue(
      element ? element.Email_Direttore || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.place.setValue(
      element ? element.Luogo_Dipartimento || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.bio.setValue(
      element ? element.CV_Direttore || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.publish.setValue(
      element ? element.published === '1' || false : false
    );
    (attributes.controls[0] as FormGroup).controls.picture.setValue(
      element ? element.Foto_Direttore || '' : ''
    );

    if (element && element.Foto_Direttore) {
      const imagePath = this._sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this._sanitizer.bypassSecurityTrustResourceUrl(element.Foto_Direttore)
      );
      filesUploaded = [
        new File([this.b64toBlob(imagePath)], 'Foto_Direttore', {
          type: 'image/jpeg',
        }),
      ];
      fileUploading = filesUploaded && element.Foto_Direttore ? true : false;
      if (filesUploaded.length > 0) {
        filesUploaded[0].imagePath = imagePath;
        setTimeout(() => {
          this.fileUploadedSubject.next(filesUploaded);
        }, 500);
      }
    }
  }

  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {
      type: 'image/jpeg',
    });
  }

  changeStatusDepartment(
    ID: any,
    published: string,
    actionFunction: () => void
  ) {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    this.departmentService.departmentSetState({ ID, published }).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);

          this.infoModal.setModal({
            show: true,
            type: 'success',
            title: this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText: this.translate.instant('DOCUMENTS.SUCCESS_STATUS_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callDepartmentsInsert(
    requestBody: {
      Titolo: string;
      Nome_Direttore: string;
      Luogo_Dipartimento: string;
      CV_Direttore: string;
      Foto_Direttore: string;
      published: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.departmentService.departmentNew(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callDepartmentsUpdate(
    requestBody: {
      ID: string;
      Titolo: string;
      Nome_Direttore: string;
      Email_Direttore: string;
      Luogo_Dipartimento: string;
      CV_Direttore: string;
      Foto_Direttore: string;
      published: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.departmentService.departmentEdit(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_HEADER_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_HEADER_EDIT'),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant('DOCUMENTS.SUCCESS_BODY_CREATE')
                : this.translate.instant('DOCUMENTS.SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * DEPARTMENT UO
   */
  initFromDepartmentUO(
    element: { Nome_Struttura: string; ID_Direttore: string },
    documentForm: FormGroup,
    attributes: FormArray
  ) {
    documentForm.controls.type.setValue(
      TypeOperationEnum.DIPARTIMENTI_UNITA_OPERATIVE
    );

    (attributes.controls[0] as FormGroup).controls.description.setValue(
      element ? element.Nome_Struttura || '' : ''
    );
    (attributes.controls[0] as FormGroup).controls.doctor.setValue(
      element ? element.ID_Direttore || '' : ''
    );
  }

  callDepartmentUoInsert(
    requestBody: {
      Nome_Struttura: string;
      ID_Direttore: string;
      Tipologia_Struttura: StructureTypeEnum;
      ID_Dai: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.departmentService.insertsUOCUOSDPrograms(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant(
                    'DOCUMENTS.GENERIC_SUCCESS_HEADER_CREATE'
                  )
                : this.translate.instant(
                    'DOCUMENTS.GENERIC_SUCCESS_HEADER_EDIT'
                  ),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant(
                    'DOCUMENTS.GENERIC_SUCCESS_BODY_CREATE'
                  )
                : this.translate.instant('DOCUMENTS.GENERIC_SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  callDepartmentDetailUpdate(
    requestBody: {
      ID: string;
      Nome_Struttura: string;
      ID_Direttore: string;
      Tipologia_Struttura: StructureTypeEnum;
      ID_Dai: string;
    },
    modalMode: string,
    actionFunction: () => void
  ) {
    this.departmentService.updateUOCUOSDPrograms(requestBody).subscribe(
      (res) => {
        if (res && res.rowCount > 0) {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'success',
            title:
              modalMode === 'create'
                ? this.translate.instant(
                    'DOCUMENTS.GENERIC_SUCCESS_HEADER_CREATE'
                  )
                : this.translate.instant(
                    'DOCUMENTS.GENERIC_SUCCESS_HEADER_EDIT'
                  ),
            bodyText:
              modalMode === 'create'
                ? this.translate.instant(
                    'DOCUMENTS.GENERIC_SUCCESS_BODY_CREATE'
                  )
                : this.translate.instant('DOCUMENTS.GENERIC_SUCCESS_BODY_EDIT'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: function () {
              actionFunction();
            }.bind(this),
          });
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
        }
      },
      (err) => {
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
      }
    );
  }

  /**
   * TOKEN
   */

  checkTokenExpired(err: { status: number }) {
    if (err.status === 401) {
      this.infoModal.setModal({
        show: true,
        type: 'error',
        title: this.translate.instant('ALERT_MESSAGE.SESSION_EXPIRED'),
        bodyText: this.translate.instant(
          'ALERT_MESSAGE.SESSION_EXPIRED_MESSAGE'
        ),
        actionLabel: this.translate.instant('BUTTON.CONFIRM'),
        actionFunction: function () {
          this.resetToken();
        }.bind(this),
      });
    } else {
      this.infoModal.setModal({
        show: true,
        type: 'error',
        title: this.translate.instant('DOCUMENTS.ERROR'),
        bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
        actionLabel: this.translate.instant('BUTTON.OK'),
        actionFunction: () => {},
      });
    }
  }
  resetToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.reload();
  }

  /**
   *  ACCEPTED
   */

  // I FILE ACCETTATI
  getAcceptedFile(documentToShow: TypeOperationEnum) {
    switch (documentToShow) {
      case TypeOperationEnum.ALTRI_CONTENUTI:
      case TypeOperationEnum.ATTIVITÁ_E_PROCEDIMENTI:
      case TypeOperationEnum.BANDI_DI_CONCORSO:
      case TypeOperationEnum.BANDI_DI_GARA_E_CONTRATTI:
      case TypeOperationEnum.BENI_IMMOBILI_E_GESTIONE_PATRIMONIO:
      case TypeOperationEnum.BILANCI:
      case TypeOperationEnum.CONSULENTI_E_COLLABORATORI:
      case TypeOperationEnum.CONTROLLI_E_RILIEVI_AMMINISTRAZIONE:
      case TypeOperationEnum.CONTROLLI_SULLE_IMPRESE:
      case TypeOperationEnum.DISPOSIZIONI_GENERALI:
      case TypeOperationEnum.ENTI_CONTROLLATI:
      case TypeOperationEnum.INFORMAZIONI_AMBIENTALI:
      case TypeOperationEnum.INTERVENTI_STRAORDINARI_E_DI_EMERGENZA:
      case TypeOperationEnum.OPERE_PUBBLICHE:
      case TypeOperationEnum.ORGANIZZAZIONE:
      case TypeOperationEnum.PAGAMENTI_AMMINISTRAZIONE:
      case TypeOperationEnum.PERFORMANCE:
      case TypeOperationEnum.PERSONALE:
      case TypeOperationEnum.PIANIFICAZIONE_E_GOVERNO_DEL_TERRITORIO:
      case TypeOperationEnum.PROVVEDIMENTI:
      case TypeOperationEnum.SOVVENZIONI_CONTRIBUTI_SUSSIDI_VANTAGGI_ECONOMICI:
      case TypeOperationEnum.SERVIZI_EROGATI:
        return 'application/pdf, 	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, zip,application/zip,application/x-zip,application/x-zip-compressed';
      case TypeOperationEnum.BANDO:
      case TypeOperationEnum.CONCORSI:
      case TypeOperationEnum.AVVISI:
      case TypeOperationEnum.PRIVACY:
        return 'application/pdf, .docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case TypeOperationEnum.CAMPANIA_PANCREAS:
      case TypeOperationEnum.CIO:
      case TypeOperationEnum.COMITATO_ETICO:
      case TypeOperationEnum.RISK:
      case TypeOperationEnum.ATTI_DI_NOTIFICA:
        return 'application/pdf, 	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, .csv ,.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case TypeOperationEnum.DIPARTIMENTI:
      case TypeOperationEnum.HOME_CAROUSEL:
        return 'image/jpeg, image /*;capture=camera';
      default:
        return 'application/pdf';
    }
  }
  // MOSTRA IL TESTO DESTO CHE RIGUARDA I FILE ACCETTATI
  getAcceptedText(documentToShow: any) {
    if(documentToShow === 'PROCEDURE'){
      return this.translate.instant('DOCUMENTS.ACCEPTED_FORMAT_3');
    }
    switch (documentToShow) {
      case TypeOperationEnum.ALTRI_CONTENUTI:
      case TypeOperationEnum.ATTIVITÁ_E_PROCEDIMENTI:
      case TypeOperationEnum.BANDI_DI_CONCORSO:
      case TypeOperationEnum.BANDI_DI_GARA_E_CONTRATTI:
      case TypeOperationEnum.BENI_IMMOBILI_E_GESTIONE_PATRIMONIO:
      case TypeOperationEnum.BILANCI:
      case TypeOperationEnum.CONSULENTI_E_COLLABORATORI:
      case TypeOperationEnum.CONTROLLI_E_RILIEVI_AMMINISTRAZIONE:
      case TypeOperationEnum.CONTROLLI_SULLE_IMPRESE:
      case TypeOperationEnum.DISPOSIZIONI_GENERALI:
      case TypeOperationEnum.ENTI_CONTROLLATI:
      case TypeOperationEnum.INFORMAZIONI_AMBIENTALI:
      case TypeOperationEnum.INTERVENTI_STRAORDINARI_E_DI_EMERGENZA:
      case TypeOperationEnum.OPERE_PUBBLICHE:
      case TypeOperationEnum.ORGANIZZAZIONE:
      case TypeOperationEnum.PAGAMENTI_AMMINISTRAZIONE:
      case TypeOperationEnum.PERFORMANCE:
      case TypeOperationEnum.PERSONALE:
      case TypeOperationEnum.PIANIFICAZIONE_E_GOVERNO_DEL_TERRITORIO:
      case TypeOperationEnum.PROVVEDIMENTI:
      case TypeOperationEnum.SOVVENZIONI_CONTRIBUTI_SUSSIDI_VANTAGGI_ECONOMICI:
      case TypeOperationEnum.SERVIZI_EROGATI:
        return this.translate.instant('DOCUMENTS.ACCEPTED_FORMAT_1');
      case TypeOperationEnum.BANDO:
      case TypeOperationEnum.CONCORSI:
      case TypeOperationEnum.AVVISI:
      case TypeOperationEnum.PRIVACY:
        return this.translate.instant('DOCUMENTS.ACCEPTED_FORMAT_2');
      case TypeOperationEnum.CAMPANIA_PANCREAS:
      case TypeOperationEnum.CIO:
      case TypeOperationEnum.ATTI_DI_NOTIFICA:
      case TypeOperationEnum.COMITATO_ETICO:
      case TypeOperationEnum.RISK:
      case TypeOperationEnum.RLS:
      case TypeOperationEnum.ENDO:
      case TypeOperationEnum.MANUALE_QUALITA:
        return this.translate.instant('DOCUMENTS.ACCEPTED_FORMAT_3');
      case TypeOperationEnum.DIPARTIMENTI:
        return this.translate.instant('DOCUMENTS.ACCEPTED_FORMAT_4');
      case TypeOperationEnum.HOME_CAROUSEL:
        return this.translate.instant('DOCUMENTS.ACCEPTED_FORMAT_6', {
          value1: this.getBannerWidthThreshold(),
          value2: this.getBannerHeightThreshold(),
        });
      default:
        return this.translate.instant('DOCUMENTS.ACCEPTED_FORMAT_5');
    }
  }

  settingRole(
    res: { rowCount?: number; message?: string; rows: any },
    tipoPubblicazione: string
  ) {
    if (res.rows[0].number === 1) {
      return tipoPubblicazione === ADMIN_VALUE ||
        tipoPubblicazione === ''.padStart(GLOBAL_BIT_NUM, '1')
        ? RoleUserEnum.ADMIN
        : RoleUserEnum.AUTHOR;
    } else {
      return RoleUserEnum.EDITOR;
    }
  }
  /**
   * SET ROLE
   */
  setRole(callback: (role: RoleUserEnum) => void): RoleUserEnum {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);
    const user = JSON.parse(localStorage.getItem('user'));
    const email = user?.email;
    const tipo_pubblicazione: string = user?.tipo_pubblicazione;
    this.managementService.Typebit(email).subscribe(
      (res: any) => {
        if (res && res.rowCount > 0) {
          const role = this.settingRole(res, tipo_pubblicazione);
          if (callback) {
            callback(role);
          }
        } else {
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText:
              res.message ||
              this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: () => {},
          });
        }
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        return RoleUserEnum.AUTHOR;
      },
      (err) => {
        console.error(err);
        this.loaderPage = false;
        this.loaderService.setloader(this.loaderPage);
        this.checkTokenExpired(err);
        return null;
      }
    );
    return undefined;
  }

  /**
   * SET PAGINATION AND DATA SOURCE
   */
  setTotalNumberOfPagesDataSource(countTotal, pageLimit) {
    return (
      Math.floor(countTotal / pageLimit) +
      (countTotal % pageLimit === 0 ? 0 : 1)
    );
  }

  /**
   * Check on delete if is last element of a list
   */
  isLastElementCheck(dataSource: Array<any>, lastPage: number) {
    if (dataSource.length == 1) {
      lastPage--;
      return lastPage;
    } else {
      return lastPage;
    }
  }

  /**
   * BANNER THRESHOLD
   */
  getBannerWidthThreshold() {
    return 1200;
  }
  getBannerHeightThreshold() {
    return 600;
  }
  /**
   *  CHECK FILE SIZES
   */
  checkFileSize(file: File, offset) {
    return !(file.size / (1024 * 1024) > offset);
  }
}
