/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {
  noticesDetailResponse,
  noticesDocumentsResponse,
  noticesPlaceResponse,
} from 'src/app/shared/messageType';

@Injectable({
  providedIn: 'root',
})
export class AvvisiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation postSearchAvvisiDetails
   */
  static readonly PostSearchAvvisiDetailsPath = '/api/v1/alerts/_search';
  static readonly PostSearchTitleAvvisiDetailsPath =
    '/api/v1/alerts/title_search';

  static readonly PutUpdatePublishPath = '/api/v1/alerts/_update_publish';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchAvvisiDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiDetails$Response(
    body?: {
      published?: string;
      filter?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDetailResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PostSearchAvvisiDetailsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchAvvisiDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiDetails(
    body?: {
      tipoavviso?: string;
      published?: string;
      filter?: string;
      titolo?: string;
      order?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDetailResponse>;
  }> {
    return this.postSearchAvvisiDetails$Response(body, params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }
      )
    );
  }

  postSearchTitleAvvisiDetails$Response(
    body?: {
      published?: string;
      titolo?: string;
      tipoavviso?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDetailResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PostSearchTitleAvvisiDetailsPath,
      'post'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }
    if (body) {
      rb.body(body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchAvvisiDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchTitleAvvisiDetails(
    body?: {
      type?: number;
      published?: string;
      titolo?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDetailResponse>;
  }> {
    return this.postSearchTitleAvvisiDetails$Response(body, params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchAvvisiDocuments
   */
  static readonly PostSearchAvvisiDocumentsPath =
    '/api/v1/alerts/{alert_id}/documents/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchAvvisiDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiDocuments$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDocumentsResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PostSearchAvvisiDocumentsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('alert_id', params.alert_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchAvvisiDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiDocuments(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDocumentsResponse>;
  }> {
    return this.postSearchAvvisiDocuments$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDocumentsResponse>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchAvvisiFaqs
   */
  static readonly PostSearchAvvisiFaqsPath =
    '/api/v1/alerts/{alert_id}/faqs/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchAvvisiFaqs()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiFaqs$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idavviso?: number;
        domanda?: string;
        risposta?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PostSearchAvvisiFaqsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('alert_id', params.alert_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchAvvisiFaqs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiFaqs(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idavviso?: number;
      domanda?: string;
      risposta?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.postSearchAvvisiFaqs$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchAvvisiNews
   */
  static readonly PostSearchAvvisiNewsPath =
    '/api/v1/alerts/{alert_id}/news/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchAvvisiNews()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiNews$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idavviso?: number;
        titolo?: string;
        testobreve?: string;
        testo?: string;
        stato?: number;
        internetview?: number;
        data?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PostSearchAvvisiNewsPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('alert_id', params.alert_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchAvvisiNews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiNews(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idavviso?: number;
      titolo?: string;
      testobreve?: string;
      testo?: string;
      stato?: number;
      internetview?: number;
      data?: string;
    }>;
  }> {
    return this.postSearchAvvisiNews$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation postSearchAvvisiDeadlines
   */
  static readonly PostSearchAvvisiDeadlinesPath =
    '/api/v1/alerts/{alert_id}/deadlines/_search';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSearchAvvisiDeadlines()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiDeadlines$Response(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idavviso?: number;
        descrizione?: string;
        data?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PostSearchAvvisiDeadlinesPath,
      'post'
    );
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('alert_id', params.alert_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSearchAvvisiDeadlines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postSearchAvvisiDeadlines(params: {
    page?: number;
    limit?: number;

    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idavviso?: number;
      descrizione?: string;
      data?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.postSearchAvvisiDeadlines$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getAvvisiDetailsById
   */
  static readonly GetAvvisiDetailsByIdPath = '/api/v1/alerts/{alert_id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvvisiDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiDetailsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesDetailResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.GetAvvisiDetailsByIdPath,
      'get'
    );
    if (params) {
      rb.path('alert_id', params.alert_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvvisiDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiDetailsById(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesDetailResponse>;
  }> {
    return this.getAvvisiDetailsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesDetailResponse>;
          }
      )
    );
  }

  /**
   * Path part for operation getAvvisiDocumentsById
   */
  static readonly GetAvvisiDocumentsByIdPath =
    '/api/v1/alerts/{alert_id}/documents/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvvisiDocumentsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiDocumentsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idavviso?: number;
        titolo?: string;
        datains?: string;
        descrizione?: string;
        docfile?: string;
        internetview?: number;
        stato?: number;
        tipodoc?: number;
        filename?: string;
        docfile1?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.GetAvvisiDocumentsByIdPath,
      'get'
    );
    if (params) {
      rb.path('alert_id', params.alert_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              datains?: string;
              descrizione?: string;
              docfile?: string;
              internetview?: number;
              stato?: number;
              tipodoc?: number;
              filename?: string;
              docfile1?: string;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvvisiDocumentsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiDocumentsById(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idavviso?: number;
      titolo?: string;
      datains?: string;
      descrizione?: string;
      docfile?: string;
      internetview?: number;
      stato?: number;
      tipodoc?: number;
      filename?: string;
      docfile1?: string;
    }>;
  }> {
    return this.getAvvisiDocumentsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              datains?: string;
              descrizione?: string;
              docfile?: string;
              internetview?: number;
              stato?: number;
              tipodoc?: number;
              filename?: string;
              docfile1?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              datains?: string;
              descrizione?: string;
              docfile?: string;
              internetview?: number;
              stato?: number;
              tipodoc?: number;
              filename?: string;
              docfile1?: string;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getAvvisiFaqsById
   */
  static readonly GetAvvisiFaqsByIdPath =
    '/api/v1/alerts/{alert_id}/faqs/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvvisiFaqsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiFaqsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idavviso?: number;
        domanda?: string;
        risposta?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.GetAvvisiFaqsByIdPath,
      'get'
    );
    if (params) {
      rb.path('alert_id', params.alert_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvvisiFaqsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiFaqsById(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idavviso?: number;
      domanda?: string;
      risposta?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.getAvvisiFaqsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              domanda?: string;
              risposta?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getAvvisiNewsById
   */
  static readonly GetAvvisiNewsByIdPath =
    '/api/v1/alerts/{alert_id}/news/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvvisiNewsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiNewsById$Response(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idavviso?: number;
        titolo?: string;
        testobreve?: string;
        testo?: string;
        stato?: number;
        internetview?: number;
        data?: string;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.GetAvvisiNewsByIdPath,
      'get'
    );
    if (params) {
      rb.path('alert_id', params.alert_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvvisiNewsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiNewsById(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idavviso?: number;
      titolo?: string;
      testobreve?: string;
      testo?: string;
      stato?: number;
      internetview?: number;
      data?: string;
    }>;
  }> {
    return this.getAvvisiNewsById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              titolo?: string;
              testobreve?: string;
              testo?: string;
              stato?: number;
              internetview?: number;
              data?: string;
            }>;
          }
      )
    );
  }

  /**
   * Path part for operation getAvvisiDeadlinesById
   */
  static readonly GetAvvisiDeadlinesByIdPath =
    '/api/v1/alerts/{alert_id}/deadlines/{id}';

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvvisiDeadlinesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiDeadlinesById$Response(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<{
        id?: number;
        idavviso?: number;
        descrizione?: string;
        data?: string;
        stato?: number;
        internetview?: number;
      }>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.GetAvvisiDeadlinesByIdPath,
      'get'
    );
    if (params) {
      rb.path('alert_id', params.alert_id, {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>;
        })
      );
  }

  /**
   * Lorem ipsum.
   *
   * Lorem ipsum...
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvvisiDeadlinesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvvisiDeadlinesById(params: {
    /**
     * Internal ID for identification.
     */
    alert_id: string;

    /**
     * Internal ID for identification.
     */
    id: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<{
      id?: number;
      idavviso?: number;
      descrizione?: string;
      data?: string;
      stato?: number;
      internetview?: number;
    }>;
  }> {
    return this.getAvvisiDeadlinesById$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<{
              id?: number;
              idavviso?: number;
              descrizione?: string;
              data?: string;
              stato?: number;
              internetview?: number;
            }>;
          }
      )
    );
  }

  static readonly PostAvvisiPlacePath = '/api/v1/notices_place';

  /**

  */
  PostAvvisiPlace$Response(
    ID_ORG,
    Avviso_ID
  ): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<noticesPlaceResponse>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PostAvvisiPlacePath,
      'post'
    );
    if (ID_ORG) {
      rb.body({ ID_ORG: ID_ORG, Avviso_ID: Avviso_ID }, 'application/json');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesPlaceResponse>;
          }>;
        })
      );
  }

  /**

   */
  PostAvvisiPlace(
    ID_ORG,
    Avviso_ID
  ): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<noticesPlaceResponse>;
  }> {
    return this.PostAvvisiPlace$Response(ID_ORG, Avviso_ID).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesPlaceResponse>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<noticesPlaceResponse>;
          }
      )
    );
  }

  /*
   * UPDATE PUBLISH
   */
  UpdatePublishAvvisi$Response(params?: {
    ID: string;
    published: string;
  }): Observable<
    StrictHttpResponse<{
      rowCount?: number;
      message?: string | null;
      rows?: Array<any>;
    }>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      AvvisiService.PutUpdatePublishPath,
      'put'
    );
    const token = localStorage.getItem('token');
    rb.header('Authorization', 'Bearer ' + token);
    if (params) {
      rb.body(params, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>;
        })
      );
  }

  UpdatePublishAvvisi(params?: {
    ID: string;
    published: string;
  }): Observable<{
    rowCount?: number;
    message?: string | null;
    rows?: Array<any>;
  }> {
    return this.UpdatePublishAvvisi$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<{
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }>
        ) =>
          r.body as {
            rowCount?: number;
            message?: string | null;
            rows?: Array<any>;
          }
      )
    );
  }
}
