// IMPORT COMPONENTS

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { CompanyComponent } from './pages/company/company.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';
import { PageHeadComponent } from './components/page_head/page_head.component';
import { HomeNewsComponent } from './components/home_news/home_news.component';
import { HomeDepartmentsComponent } from './components/home_departments/home_departments.component';
import { HomeRankingsComponent } from './components/home_rankings/home_rankings.component';
import { DepartmentsComponent } from './pages/departments/departments.component';
import { DepartmentsCardComponent } from './components/departments/departments_card/departments_card.component';
import { NoticesCardComponent } from './components/notices_card/notices_card.component';
import { NoticesComponent } from './pages/notices/notices.component';
import { AlboComponent } from './components/albo_pretorio/albo_pretorio.component';
import AlboPostComponent from './components/albo_pretorio_post/albo_pretorio_post.component';
import { NewsCardComponent } from './components/news_card/news_card.component';
import { NewsComponent } from './pages/news/news.component';
import { DepartmentsDetailComponent } from './pages/departments/departments_detail/departments_detail.component';
import { DepartmentsDetailBodyComponent } from './components/departments/departments_detail_body/departments_detail_body.component';
import { DepartmentsDetailLocationComponent } from './components/departments/departments_detail_location/departments_detail_location.component';
import { DepartmentsDetailPersonComponent } from './components/departments/departments_detail_person/departments_detail_person.component';
import { DepartmentsDetailAmbulatoryComponent } from './components/departments/departments_detail_ambulatory/departments_detail_ambulatory.component';
import { NewsDetailComponent } from './pages/news/news_detail/news_detail.component';
import { NewsDetailBodyComponent } from './components/news_detail_body/news_detail_body.component';

// IMPORT SERVICES
import { SharedModule } from './shared/shared.module';
import { BookingAndContactsComponent } from './pages/booking-and-contacts/booking-and-contacts.component';
import { NoticesDetailBodyComponent } from './components/notices_detail_body/notices_detail_body.component';
import { NoticesDetailComponent } from './pages/notices/notices_detail/notices_detail.component';
import { URPBodyComponent } from './components/URP_body/URP_body.component';
import { URPComponent } from './pages/URP/URP.component';
import { URPBodyCellComponent } from './components/UPR_body_cell/URP_body_cell.component';
import { RouterModule } from '@angular/router';
import { WithdrawalContainerComponent } from './components/withdrawal-container/withdrawal-container.component';
import { WithdrawalMedicalRecordsComponent } from './pages/withdrawal-medical-records/withdrawal-medical-records.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PlaceDepartmentsCardComponent } from './components/departments/place-departments-card/place-departments-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EthicsCommitteeComponent } from './pages/ethics-committee/ethics-committee.component';
import { LegalNotesComponent } from './pages/legal-notes/legal-notes.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ActionViewsComponent } from './components/action-views/action-views.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './pages/login/login.component';
import { ManagementTaskComponent } from './components/management-task/management-task.component';
import { AuthBannerComponent } from './components/auth-banner/auth-banner.component';
import { RiskManagementListComponent } from './pages/risk-management-list/risk-management-list.component';
import { CampaniaPancreasComponent } from './pages/campania-pancreas/campania-pancreas.component';
import { SearchComponent } from './components/search/search.component';
import { AccessibilityButtonComponent } from './components/accessibility-button/accessibility-button.component';
import { CioComponent } from './pages/cio/cio.component';
import { DetailHeadComponent } from './components/detail-head/detail-head.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { InEvidenceBodyComponent } from './components/in-evidence-body/in-evidence-body.component';
import { TendersComponent } from './pages/tenders/tenders.component';
import { TransparentAdministrationEvidenceCardComponent } from './components/transparent-administration-evidence-card/transparent-administration-evidence-card.component';
import { MainAllBodyComponent } from './components/main-all-body/main-all-body.component';
import { TendersDetailComponent } from './pages/tenders/tenders-detail/tenders-detail.component';
import { BannerInfoComponent } from './components/banner-info/banner-info.component';
import { EthicsCommitteeDetailComponent } from './pages/ethics-committee/ethics-committee-detail/ethics-committee-detail.component';
import { EthicsCommitteeDetailBodyComponent } from './components/ethics-committee-detail-body/ethics-committee-detail-body.component';
import { AttiNotificaComponent } from './pages/atti-notifica/atti-notifica.component';
import { DepartmentsReadMoreComponent } from './pages/departments/departments-read-more/departments-read-more.component';

import { WorkInProgressComponent } from './pages/work-in-progress/work-in-progress.component';
import { RiskComponent } from './pages/risk/risk.component';
import { RiskDetailComponent } from './pages/risk/risk-detail/risk-detail.component';
import { RiskDetailBodyComponent } from './components/risk-detail-body/risk-detail-body.component';

import { WhistleblowingComponent } from './pages/whistleblowing/whistleblowing.component';
import { SharedData } from './shared/sharedData';
import { CompanyReadMoreComponent } from './pages/company/company-read-more/company-read-more.component';
import { CompanyManagementCardComponent } from './components/company-management-card/company-management-card.component';
import { CompanyDetailsComponent } from './pages/company/company-details/company-details.component';
import { CompanyPresentationCardComponent } from './components/company-presentation-card/company-presentation-card.component';
import { MainAccordionTableComponent } from './components/main-accordion-table/main-accordion-table.component';
import { DatePipe } from '@angular/common';
import { HomeCarouselComponent } from './components/home-carousel/home-carousel.component';
import { RegulationAndPublicProtecionComponent } from './pages/regulation-and-public-protecion/regulation-and-public-protecion.component';
import { HeaderTopComponent } from './components/header-top/header-top.component';
import { RlsComponent } from './pages/rls/rls.component';
import { ProcedureAziendaliComponent } from './pages/proc-aziend/proc-aziend.component';
import { ProcAziendDetailComponent } from './pages/proc-aziend/proc-aziend-detail/proc-aziend-detail.component';
import { ProcAziendDetailBodyComponent } from './components/proc-aziend-detail-body/proc-aziend-detail-body.component';
import { ManualeQualitaComponent } from './pages/manuale-qualita/manuale-qualita.component';
import { EndoComponent } from './pages/endo-pfd/endo-pfd.component';
import { AvvisiComponent } from './pages/avvisi/avvisi.component';
import { AvvisiDetailComponent } from './pages/avvisi/avvisi_detail/avvisi_detail.component';
import { AvvisiDetailBodyComponent } from './components/avvisi_detail_body/avvisi_detail_body.component';
import { AlboArchivioComponent } from './components/albo_pretorio_archivio/albo_pretorio_archivio.component';

// START OF DECLARATIONS

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // company
    CompanyComponent,
    // static page
    // home
    HomeComponent,
    CompanyComponent,
    PageHeadComponent,
    HomeNewsComponent,
    HomeDepartmentsComponent,
    HomeRankingsComponent,
    // departments
    DepartmentsComponent,
    DepartmentsCardComponent,
    // departments detail
    DepartmentsDetailComponent,
    DepartmentsDetailBodyComponent,
    DepartmentsDetailLocationComponent,
    DepartmentsDetailPersonComponent,
    DepartmentsDetailAmbulatoryComponent,
    DepartmentsReadMoreComponent,
    // notices
    NoticesComponent,
    AvvisiComponent,
    NoticesCardComponent,
    NoticesDetailComponent,
    AvvisiDetailComponent,
    NoticesDetailBodyComponent,
    AvvisiDetailBodyComponent,
    // albo
    AlboComponent,
    AlboArchivioComponent,
    AlboPostComponent,
    // news
    NewsCardComponent,
    NewsComponent,
    NewsCardComponent,
    NewsDetailComponent,
    NewsDetailBodyComponent,
    BookingAndContactsComponent,
    // urp
    URPComponent,
    URPBodyComponent,
    URPBodyCellComponent,
    // widrowl
    WithdrawalMedicalRecordsComponent,
    WithdrawalContainerComponent,
    PlaceDepartmentsCardComponent,

    PrivacyComponent,
    ManualeQualitaComponent,
    EthicsCommitteeComponent,
    ProcedureAziendaliComponent,
    RlsComponent,
    LegalNotesComponent,
    PrivacyPolicyComponent,
    ActionViewsComponent,
    LoaderComponent,
    LoginComponent,
    ManagementTaskComponent,
    AuthBannerComponent,
    RiskManagementListComponent,
    CampaniaPancreasComponent,
    SearchComponent,
    AccessibilityButtonComponent,
    CioComponent,
    EndoComponent,
    DetailHeadComponent,
    SearchFilterComponent,
    InEvidenceBodyComponent,
    TendersComponent,
    TransparentAdministrationEvidenceCardComponent,
    MainAllBodyComponent,
    TendersDetailComponent,
    BannerInfoComponent,
    EthicsCommitteeDetailComponent,
    ProcAziendDetailComponent,
    EthicsCommitteeDetailBodyComponent,
    ProcAziendDetailBodyComponent,
    AttiNotificaComponent,
    WorkInProgressComponent,
    RiskComponent,
    RiskDetailComponent,
    RiskDetailBodyComponent,
    WhistleblowingComponent,
    CompanyReadMoreComponent,
    CompanyManagementCardComponent,
    CompanyDetailsComponent,
    CompanyPresentationCardComponent,
    MainAccordionTableComponent,
    HomeCarouselComponent,
    RegulationAndPublicProtecionComponent,
    HeaderTopComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],

  providers: [SharedData, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
