<!-- HEAD -->
<app-main-head
  [breadcrumbItems]="breadcrumbItems"
  [isSearchRequired]="false"
  [mainTitle]="detailInformation?.post_title"
  [isRightPanelVisible]="false"
>
</app-main-head>
<div *ngIf="detailInformation" class="detail_body">
  <p class="detail_intro" innerHTML="{{ detailInformation.post_excerpt }}"></p>
  <div class="info-news">
    <div class="info-elem">
      <span class="title">{{ "NOTICES.DATE" | translate }}:</span>
      <span class="value">{{
        detailInformation.post_date | date : "dd MMMM yyyy" : "" : "it"
      }}</span>
    </div>
    <div class="info-elem">
      <span class="title">{{ "NOTICES.READING_TIME" | translate }}:</span>
      <span class="value">
        {{ detailInformation.post_content | readingTime }}</span
      >
    </div>
  </div>
  <div class="image-container">
    <img [src]="detailInformation.post_image_url" alt="Post-Image" />
  </div>
</div>
<!-- END HEAD -->

<app-news-detail-body
  *ngIf="detailInformation"
  [detailInformation]="detailInformation"
></app-news-detail-body>

<!-- WTF ?! -->
<div *ngIf="!detailInformation" style="height: calc(100vh - 700px)"></div>
