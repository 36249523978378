import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoticesService } from 'src/app/api/services';
import { noticesDetailResponse } from 'src/app/shared/messageType';
import { Location } from '@angular/common';
import { LoaderService } from 'src/app/api/services/loader.service';
import { GenericInfoModalService } from 'src/app/components/generic-info-modal/generic-info-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumbItem.model';

@Component({
  selector: 'app-avvisi-detail',
  templateUrl: './avvisi_detail.component.html',
  styleUrls: [
    './avvisi_detail.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class AvvisiDetailComponent implements OnInit {
  breadcrumbItems: BreadcrumbItem[];

  constructor(
    private route: ActivatedRoute,
    private ns: NoticesService,
    private location: Location,
    private infoModal: GenericInfoModalService,
    private translate: TranslateService,
    private loaderService: LoaderService
  ) {}
  loaderPage = false;
  notice_Detail_Response: noticesDetailResponse;

  ngOnInit(): void {
    this.loaderPage = true;
    this.loaderService.setloader(this.loaderPage);

    this.ns
      .getAvvisiDetailsById({ alert_id: this.route.snapshot.params.id })
      .subscribe(
        (res) => {
          this.loaderPage = true;
          this.loaderService.setloader(this.loaderPage);
          this.notice_Detail_Response = res.rows[0];
          this.breadcrumbItems = [
            new BreadcrumbItem('NOTICES_DETAIL.HOME', '/home'),
            new BreadcrumbItem('NOTICES_DETAIL.AVVISI', '/avvisi'),
            new BreadcrumbItem(this.notice_Detail_Response.titolo, ''),
          ];
        },
        (err) => {
          this.loaderPage = false;
          this.loaderService.setloader(this.loaderPage);
          this.infoModal.setModal({
            show: true,
            type: 'error',
            title: this.translate.instant('ALERT_MESSAGE.TITLE_ERROR'),
            bodyText: this.translate.instant('ALERT_MESSAGE.GENERIC_ERROR'),
            actionLabel: this.translate.instant('BUTTON.OK'),
            actionFunction: {},
          });
        }
      );
  }
}
