<div class="center">
  <table class="table" *ngIf="
      !loaderPage && resolutionServiceList && resolutionServiceList.length > 0
    " role="table" aria-label="table">

    <div *ngIf="mobile">
      <app-main-accordion-table [dataSource]="resolutionServiceList" [hasAttachment]="true" [hasDetail]="true"
        [typology]="typologyName" (downloadHandler)="downloadFile($event)"
        (detailHandler)="showDetail($event)"></app-main-accordion-table>
    </div>

    <div *ngIf="!mobile">
      <thead class="table-header" role="table">
        <tr>
          <th scope="col" class="colonna1" role="columnheader">
            {{ "ALBO_PRETORIO.OGGETTO" | translate }}
          </th>
          <th scope="col" class="colonna2" role="columnheader" [attr.aria-label]="'numero'">
            {{ "ALBO_PRETORIO.NUMERO" | translate }}
          </th>
          <th scope="col" class="colonna3" role="columnheader">
            {{ "ALBO_PRETORIO.START_DATE" | translate }}
          </th>
          <th scope="col" class="colonna4" role="columnheader">
            {{ "ALBO_PRETORIO.ATTACHMENTS" | translate }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of resolutionServiceList">
          <td class="table-body-font bold text-align-left">
            {{ element.titolo }}
          </td>
          <td class="table-body-font text-align-center">
            {{ element.numero }}
          </td>
          <td class="table-body-font text-align-left">
            {{ getStartDate(element) }}
          </td>
          <td class="table-body-font text-align-left" style="padding: 0">
            <button class="btn download_button" aria-label="Download" (click)="downloadFile(element)">
              <div class="col-12 col-md-6 col-lg-4">
                <svg class="icon icon-sm icon-primary">
                  <use xlink:href="../../../assets/sprite.svg#it-download"></use>
                </svg>
              </div>
            </button>
          </td>
          <td class="table-body-font text-align-left">
            <button (click)="showDetail(element)" class="btn detail">
              {{ "ALBO_PRETORIO.DETAILS" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </div>
  </table>
</div>

<div>
  <div class="text-center" *ngIf="loaderPage">
    <div class="col-lg-12">
      <p style="
          text-align: center;
          font-size: 0.875em;
          letter-spacing: 0.03125em;
          font-weight: 500;
          position: relative;
          width: 95%;
        ">
        {{ "MESSAGE.LOADING" | translate }}
      </p>
      <div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
    </div>
  </div>

  <p *ngIf="
      !loaderPage &&
      !(resolutionServiceList && resolutionServiceList.length > 0)
    " class="no_results">
    {{ "MESSAGE.NO_RESULTS" | translate }}
  </p>

  <div>
    <app-paginator [loader]="loaderPage" [list]="resolutionServiceList" [isIntoPaginationLimit]="limitPagination"
      [currentPage]="currentPage" [totalNumberOfPages]="totalNumberOfPages" (didClickedOnPage)="updateList($event)">
    </app-paginator>
  </div>

  <!-- Modal -->
  <div [class.it-dialog-scrollable]="" class="modal it-dialog-scrollable fade" tabindex="-1" role="dialog"
    id="alboPretorioModal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="margin: 0 auto">
      <div *ngIf="selectedDetail" class="modal-content">
        <div class="modal-header">
          <div class="row">
            <div class="col">
              <p class="modal-title" style="margin-bottom: 0.625em">
                <b>{{ "ALBO_PRETORIO.OGGETTO" | translate }} </b>
              </p>
            </div>
            <div class="w-100"></div>
            <div class="col">
              <p>{{ selectedDetail.titolo }}</p>
            </div>
          </div>
          <button (click)="closeModal()" data-dismiss="modal" class="close" type="button" data-dismiss="modal"
            aria-label="Close">
            <svg class="icon icon-secondary">
              <use xlink:href="../../../assets/sprite.svg#it-close"></use>
            </svg>
          </button>
        </div>

        <div class="modal-header" style="padding-top: 0">
          <div class="row" *ngIf="selectedDetail.descrizione">
            <div class="col">
              <p class="element_title">
                <b>{{ "ALBO_PRETORIO.DESCRIPTION" | translate }} </b>
              </p>
            </div>
            <div class="w-100"></div>
            <div class="col">{{ selectedDetail.descrizione }}</div>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col">
                <b> {{ "ALBO_PRETORIO.RELATORI" | translate }} </b>
              </div>
              <div class="col">
                <b> {{ "ALBO_PRETORIO.EMISSIONE" | translate }} </b>
              </div>
              <div class="w-100"></div>
              <div class="col">{{ selectedDetail.relatori }}</div>
              <div class="col">
                {{ selectedDetail.dataemissione | date : "dd MMMM yyyy" : "" : "it" }}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <b> {{ "ALBO_PRETORIO.START_DATE" | translate }} </b>
              </div>
              <div class="w-100"></div>
              <div class="col">
                {{ selectedDetail.datainizio | date : "dd MMMM yyyy" : "" : "it" }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button (click)="downloadFile(selectedDetail)" type=" button" class="btn btn-primary">
              <svg-icon-sprite src="../../../assets/sprite.svg#it-download" classes="icon icon-sm icon-white">
              </svg-icon-sprite>
              <span class="add-btn-text">{{ "ALBO_PRETORIO.DOWNLOAD_ATTACHMENT" | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>