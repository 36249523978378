<div class="shared_detail_head">
  <div class="shared_detail_head_left rectangle">
    <app-breadcrumb
      *ngIf="!transparent"
      [breadcrumbList]="breadcrumbItems"
    ></app-breadcrumb>

    <div style="display: flex" role="region" aria-label="descrizione">
      <p class="shared_detail_Title">{{ contextTitle | translate }}</p>
      <p 
        class="shared_detail_intro intro-label"
        style="font-weight: 500; font-style: italic"
      >
        {{ contextParagraph | translate }}
      </p>

    </div>

    <search
      *ngIf="!transparent"
      [textPlaceholder]="contextTitle"
      (searchEvent)="search($event)"
    ></search>

    <app-search-filter
      id="search-filter"
      [textPlaceholder]="contextTitle"
      [visible]="transparent"
      [(selectedTempYear)]="selectedTempYear"
      [(tempSearchText)]="tempSearchText"
      [showNumber]="true"
      [showYear]="true"
      [(tempSearchNumber)]="tempSearchNumber"
      [years]="years"
      (resetFilter)="resetFilter()"
      (doSearch)="doSearch()"
    >
    </app-search-filter>
  </div>
</div>

<albo_post
  [type]="type"
  [addDate]="'d'"
  [filters]="{ title: searchText, year: selectedYear, number: selectedNumber }"
>
</albo_post>
