<div class="shared_detail_body">
  <div class="shared_index_column">
    <div class="menu-wrapper">
      <div class="link-list-wrapper">
        <p class="shared_index_column_title">
          {{ "TRANSPARENT_ADMINSTRATION.DETAIL.PAGE_INDEX" | translate }}
        </p>
        <ul class="link-list">
          <li class="nav-item">
            <a (click)="redirectTo('description')" class="nav-link active">
              <p class="shared_index_column_element">
                {{ "NOTICES_DETAIL.DESCRIPTION" | translate }}
              </p>
            </a>
          </li>
          <li *ngIf="notice_Place_Response" class="nav-item">
            <a class="nav-link active" (click)="redirectTo('location')">
              <p class="shared_index_column_element">
                {{ "NOTICES_DETAIL.LOCATION" | translate }}
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" (click)="redirectTo('date')">
              <p class="shared_index_column_element">
                {{ "NOTICES_DETAIL.DATE" | translate }}
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" (click)="redirectTo('documents')">
              <p class="shared_index_column_element">
                {{ "NOTICES_DETAIL.DOCUMENTS" | translate }}
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              (click)="redirectTo('ulterior_Information')"
            >
              <p class="shared_index_column_element">
                {{ "NOTICES_DETAIL.ULTERIOR_INFORMATION" | translate }}
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="shared_information_column">
    <p
      *ngIf="notice_Detail_Response"
      class="shared_paragraph_title"
      id="description"
    >
      {{ "NOTICES_DETAIL.DESCRIPTION" | translate }}
    </p>
    <p class="shared_text">
      {{
        notice_Detail_Response && notice_Detail_Response.testolungo
          ? notice_Detail_Response.testolungo
          : notice_Detail_Response.testobreve
      }}
    </p>
    <p
      *ngIf="notice_Place_Response"
      class="shared_paragraph_title"
      id="location"
    >
      {{ "NOTICES_DETAIL.LOCATION" | translate }}
    </p>
    <div
      class="shared_information_cards_container"
      *ngIf="notice_Place_Response"
    >
      <app-departments-detail-location
        *ngIf="notice_Place_Response"
        [details]="notice_Place_Response"
      >
      </app-departments-detail-location>
    </div>
    <p class="shared_paragraph_title" id="date">
      {{ "NOTICES_DETAIL.DATE" | translate }}
    </p>
    <div class="timeline-container">
      <div class="it-timeline-wrapper">
        <div class="row">
          <div class="col-12">
            <div class="timeline-element">
              <div class="it-pin-wrapper it-evidence">
                <div class="pin-icon">
                  <!-- <svg class="icon"> -->
                    <img
                    class="icon"
                    src= {{sprite}}#it-code-circle
                    />
                  <!-- </svg> -->
                </div>
                <div class="pin-text">
                  <span
                    >{{
                      notice_Detail_Response.iniziovalidita
                        | date : "dd" : "" : "it"
                    }}
                    {{
                      notice_Detail_Response.iniziovalidita
                        | date : "MMMM" : "" : "it"
                    }}</span
                  >
                </div>
              </div>
              <div class="card-wrapper">
                <div class="card">
                  <div>
                    <h5 class="card-title">
                      {{
                        notice_Detail_Response &&
                        notice_Detail_Response.TestoData1 !== ""
                          ? notice_Detail_Response.TestoData1
                          : ("NOTICES_DETAIL.START_ALERTS" | translate)
                      }}
                    </h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="timeline-element">
              <div class="it-pin-wrapper it-evidence">
                <div class="pin-icon">
                  <!-- <svg class="icon">
                    <use
                      href="/bootstrap-italia/dist/svg/sprites.svg#it-code-circle"
                    ></use>
                  </svg> -->
                  <img
                  class="icon"
                  src= {{sprite}}#it-code-circle
                  />
                </div>
                <div class="pin-text">
                  <span>
                    {{
                      notice_Detail_Response.finevalidita
                        | date : "dd" : "" : "it"
                    }}
                    {{
                      notice_Detail_Response.finevalidita
                        | date : "MMMM" : "" : "it"
                    }}</span
                  >
                </div>
              </div>
              <div class="card-wrapper">
                <div class="card">
                  <div>
                    <h5 class="card-title">
                      {{
                        notice_Detail_Response &&
                        notice_Detail_Response.TestoData2 !== ""
                          ? notice_Detail_Response.TestoData2
                          : ("NOTICES_DETAIL.END_ALERTS" | translate)
                      }}
                    </h5>
                    <p class="card-text"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p
      *ngIf="notice_Document_Response && notice_Document_Response.length > 0"
      class="shared_paragraph_title"
      id="documents"
    >
      {{ "NOTICES_DETAIL.DOCUMENTS" | translate }}
    </p>

    <div
      *ngIf="notice_Document_Response && notice_Document_Response.length > 0"
      class="row row-cols-1 cols-sm-1 cols-md-1 row-cols-lg-3"
    >
      <ng-container *ngFor="let element of notice_Document_Response">
        <app-info-card
          style="margin: 0 2.5%"
          [data]="element"
          (actionFunction)="downloadFile($event)"
          [title]="element.filename"
          [postTypeData]="notices"
          [showDetail]="true"
          [showSubText]="false"
          [minHeight]="'130px'"
        >
        </app-info-card>
      </ng-container>
    </div>

    <ng-container
      *ngIf="notice_Document_Response && notice_Document_Response.length > 0"
    >
      <p class="shared_paragraph_title" id="ulterior_Information">
        {{ "NOTICES_DETAIL.ULTERIOR_INFORMATION" | translate }}
      </p>
      <p
        *ngIf="
          notice_Document_Response &&
          notice_Document_Response[0] &&
          notice_Document_Response[0].datains
        "
        class="last_Update"
      >
        {{ "NOTICES_DETAIL.LAST_UPDATE" | translate }}
      </p>
      <p
        *ngIf="
          notice_Document_Response &&
          notice_Document_Response[0] &&
          notice_Document_Response[0].datains
        "
        class="last_Update_Date"
      >
        {{
          notice_Document_Response[0].datains | date : "dd MMMM yyyy" : "" : "it"
        }}
      </p>
    </ng-container>
  </div>
</div>
