<div class="rankings">
  <!-- <p role="region" aria-label="HOME_SUBVIEWS.RANKINGS" class="text1">
    {{ "HOME_SUBVIEWS.RANKINGS" | translate }}
  </p> -->
  <p role="region" aria-label="HOME_SUBVIEWS.NOTICES" class="text2">
    {{ "HOME_SUBVIEWS.NOTICES" | translate }}
  </p>
  <div class="rankings_Cards">
    <app-notices-card
      [typology]="'notices'"
      *ngFor="let element of noticesList"
      [data]="element"
    >
    </app-notices-card>
  </div>

  <div class="see-all-btn-container">
    <a
      role="navigation"
      aria-label="HOME_SUBVIEWS.SHOW_ALL"
      [routerLink]="'/concorsi-e-avvisi'"
      class="btn btn-primary"
      >{{ "HOME_SUBVIEWS.SHOW_ALL" | translate }}</a
    >
  </div>
</div>
<hr />
<div class="rankings">
  <!-- <p role="region" aria-label="HOME_SUBVIEWS.RANKINGS" class="text1">
    {{ "HOME_SUBVIEWS.RANKINGS" | translate }}
  </p> -->
  <p role="region" aria-label="HOME_SUBVIEWS.TENDERS" class="text2">
    {{ "HOME_SUBVIEWS.TENDERS" | translate }}
  </p>
  <div class="rankings_Cards">
    <app-notices-card
      [typology]="'tenders'"
      *ngFor="let element of tendersList"
      [data]="element"
    >
    </app-notices-card>
  </div>

  <div class="see-all-btn-container">
    <a
      role="navigation"
      aria-label="HOME_SUBVIEWS.SHOW_ALL"
      [routerLink]="'/bandi-e-gare'"
      class="btn btn-primary"
      >{{ "HOME_SUBVIEWS.SHOW_ALL" | translate }}</a
    >
  </div>
</div>
